import React from "react";
import { Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import delhidioceselogo from "../../../assets/img/logo-for-cert.png";

const LetterHeadTwo = ({ churchDetails, certConfig }) => {
  return (
    <View style={styles.header}>
      <View style={{ marginRight: 0 }}>
        {churchDetails?.logoUrl && (
          <Image
            style={[styles.image, { maxHeight: 100, maxWidth: 100 }]}
            src={churchDetails?.logoUrl}
          />
        )}
      </View>

      <View
        style={{
          height: 72,
          width: 72,
          position: "absolute",
          top: -30,
          right: -15,
        }}
      >
        <Image style={{ height: 72, width: "auto" }} src={delhidioceselogo} />
      </View>

      {/* Right side - Church Details */}
      <View>
        <Text
          style={{
            fontSize: 24,
            fontFamily: "Mate",
            textAlign: "left",
          }}
        >
          {churchDetails?.churchName}
        </Text>

        {/* Horizontal Divider */}
        <View
          style={{
            height: 2,
            backgroundColor: certConfig?.border?.outerBorderColor
              ? certConfig?.border?.outerBorderColor
              : "#d4af37",
            marginVertical: 5,
          }}
        />

        <Text
          style={{
            fontSize: 12,
            fontFamily: "Mate",
            textAlign: "left",
          }}
        >
          {churchDetails?.churchAddress?.addressLine1}
        </Text>
      </View>
    </View>
  );
};

export default LetterHeadTwo;

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 20,
    marginBottom: 10,
  },
  image: {
    maxHeight: 130,
    maxWidth: 130,
    marginRight: 10,
  },
  headerText: {
    textAlign: "center",
    width: "100%",
  },
});
