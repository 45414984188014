import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import MarriageFormStep1 from "../../forms/marriage/MarriageFormStep1";
import MarriageFormStep2 from "../../forms/marriage/MarriageFormStep2";
import MarriageFormStep3 from "../../forms/marriage/MarriageFormStep3";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import {
  MarriageFormStep1ValidationSchema,
  MarriageFormStep2ValidationSchema,
  MarriageFormStep3ValidationSchema,
} from "../../validations/marriage";
import Swal from "sweetalert2";
import Select from "react-select";
import { useSelector } from "react-redux";

const AddMarriageDetailsModal = ({
  show,
  setShow,
  basicDetails,
  holyConfirmationDetails,
  getFamilyMemberDetails,
  commonAPIRequest,
  familyDetails,
  memberId,
  baptismDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState(1);
  const [wifeDetails, setWifeDetails] = useState();
  const [potentialBrides, setPotentialBrides] = useState([]);
  const [selectedWife, setSelectedWife] = useState({});
  const [brideConfirmationDone, setBrideConfirmationDone] = useState();
  const { churchDetails } = useSelector((state) => state?.church);

  const formik1 = useFormik({
    initialValues: {
      marriageDate: "",
      marriagePlace: "",
      confirmationDate: "",
      confirmationDateForValidation: "",
      firstBannesDate: "",
      secondBannesDate: "",
      thirdBannesDate: "",
      firstWitnessFirstName: "",
      firstWitnessLastName: "",
      firstWitnessDomicile: "",
      secondWitnessFirstName: "",
      secondWitnessLastName: "",
      secondWitnessDomicile: "",
      ministerName: "",
      impediment: "",
      remark: "",
      registerRefNumber: null,
      alternateRegRefNumber: null,
      bookNumber: "",
      marriageDateNotSure: false,
    },
    validationSchema: MarriageFormStep1ValidationSchema,
    onSubmit: (values) => {
      if (!(currentState >= 3)) {
        setCurrentState((prevValue) => prevValue + 1);
      }
    },
  });

  const formik2 = useFormik({
    initialValues: {
      groomFirstName: "",
      groomMiddleName: "",
      groomLastName: "",
      groomFatherName: "",
      groomMotherName: "",
      groomDob: "",
      groomNationality: "",
      groomDomicile: "",
      groomProfession: "",
      groomMarriageStatus: "",
      previousWifeName: "",
    },
    validationSchema: MarriageFormStep2ValidationSchema,
    onSubmit: (values) => {
      if (!(currentState >= 3)) {
        setCurrentState((prevValue) => prevValue + 1);
      }
    },
  });

  const formik3 = useFormik({
    initialValues: {
      brideFirstName: "",
      brideMiddletName: "",
      brideLastName: "",
      brideFatherName: "",
      brideMotherName: "",
      brideDob: "",
      brideNationality: "",
      brideDomicile: "",
      brideProfession: "",
      brideMarriageStatus: "",
      previousHusbandName: "",
      brideMemberId: "",
    },
    validationSchema: MarriageFormStep3ValidationSchema,
    onSubmit: (values) => {
      callAddMarriageDetailsApi();
    },
  });

  // function to call API for Add Marriage start

  const callAddMarriageDetailsApi = () => {
    let serviceParams = {
      api: API.EXTERNAL_MARRIAGE_API,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        marriageDetail: {
          marriageDate: formik1.values.marriageDate,
          marriagePlace: formik1.values.marriagePlace,
          groomFirstName: formik2.values.groomFirstName.trim(),
          groomMiddleName: formik2.values.groomMiddleName.trim(),
          groomLastName: formik2.values.groomLastName.trim(),
          groomFatherName: formik2.values.groomFatherName.trim(),
          groomMotherName: formik2.values.groomMotherName.trim(),
          groomDob: formik2.values.groomDob,
          groomNationality: formik2.values.groomNationality.trim(),
          groomDomicile: formik2.values.groomDomicile.trim(),
          groomProfession: formik2.values.groomProfession.trim(),
          groomMarriageStatus: formik2.values.groomMarriageStatus,
          brideFirstName: formik3.values.brideFirstName.trim(),
          brideMiddleName: formik3.values.brideMiddletName.trim(),
          brideLastName: formik3.values.brideLastName.trim(),
          brideFatherName: formik3.values.brideFatherName.trim(),
          brideMotherName: formik3.values.brideMotherName.trim(),
          brideDob: formik3.values.brideDob,
          brideNationality: formik3.values.brideNationality.trim(),
          brideDomicile: formik3.values.brideDomicile.trim(),
          brideProfession: formik3.values.brideProfession.trim(),
          brideMarriageStatus: formik3.values.brideMarriageStatus,
          pastHusbandName: formik3.values.previousHusbandName.trim(),
          pastWifeName: formik2.values.previousWifeName.trim(),
          firstBannsDate: formik1.values.firstBannesDate,
          secondBannsDate: formik1.values.secondBannesDate,
          thirdBannsDate: formik1.values.thirdBannesDate,
          impediment: formik1.values.impediment.trim(),
          firstWitnessFirstName: formik1.values.firstWitnessFirstName.trim(),
          firstWitnessLastName: formik1.values.firstWitnessLastName.trim(),
          firstWitnessDomicile: formik1.values.firstWitnessDomicile.trim(),
          secondWitnessFirstName: formik1.values.secondWitnessFirstName.trim(),
          secondWitnessLastName: formik1.values.secondWitnessLastName.trim(),
          secondWitnessDomicile: formik1.values.secondWitnessDomicile.trim(),
          remark: formik1.values.remark.trim(),
          active: true,
          ministerName: formik1.values.ministerName.trim(),
          regReferenceNum: formik1.values?.registerRefNumber
            ? +formik1.values?.registerRefNumber
            : null,
          marriageDateAccurate: !formik1.values.marriageDateNotSure,
        },
        brideMemberId: formik3.values.brideMemberId,
        groomMemberId: memberId,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire(
            "Success",
            `Marriage Details Added Successfully.`,
            "success"
          );
          getFamilyMemberDetails();
          setShow(false);
          formik1.resetForm();
          formik2.resetForm();
          formik3.resetForm();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Add Marriage end

  // function to fetch all members details in the family start

  const getAllFamilyMembersDetailsApi = () => {
    let serviceParams = {
      api: `${API.GET_ALL_FAMILY_MEMBERS}?familyId=${familyDetails?.familyId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          if (basicDetails.familyHead) {
            // let wifeMemberId =
            //   result.payload.familyMemberDetails &&
            //   result.payload.familyMemberDetails.filter(
            //     (member) => member.relationToHead === "WIFE"
            //   )[0].memberId;
            // getWifeMemberDetails(wifeMemberId);

            // formik3.setFieldValue("brideMemberId", wifeMemberId);
            let wifes = result.payload.familyMemberDetails.filter(
              (member) =>
                member.relationToHead === "WIFE" &&
                member?.marriageStatus === "SINGLE"
            );
            setPotentialBrides(
              wifes.map((wife) => {
                return {
                  label: `${wife?.firstName} ${wife?.middleName} ${wife?.lastName}`,
                  value: wife?.memberId,
                };
              })
            );
          } else if (basicDetails?.relationToHead === "SON") {
            let wifes = result.payload.familyMemberDetails.filter(
              (member) =>
                member.relationToHead === "DAUGHTER IN LAW" &&
                member?.marriageStatus === "SINGLE"
            );
            setPotentialBrides(
              wifes.map((wife) => {
                return {
                  label: `${wife?.firstName} ${wife?.middleName} ${wife?.lastName}`,
                  value: wife?.memberId,
                };
              })
            );
          } else if (basicDetails?.relationToHead === "BROTHER") {
            let wifes = result.payload.familyMemberDetails.filter(
              (member) =>
                member.relationToHead === "SISTER IN LAW" &&
                member?.marriageStatus === "SINGLE"
            );
            setPotentialBrides(
              wifes.map((wife) => {
                return {
                  label: `${wife?.firstName} ${wife?.middleName} ${wife?.lastName}`,
                  value: wife?.memberId,
                };
              })
            );
          } else if (basicDetails?.relationToHead === "GRAND SON") {
            let wifes = result.payload.familyMemberDetails.filter(
              (member) =>
                member.relationToHead === "GRAND DAUGHTER IN LAW" &&
                member?.marriageStatus === "SINGLE"
            );
            setPotentialBrides(
              wifes.map((wife) => {
                return {
                  label: `${wife?.firstName} ${wife?.middleName} ${wife?.lastName}`,
                  value: wife?.memberId,
                };
              })
            );
          } else if (basicDetails?.relationToHead === "UNCLE") {
            let wifes = result.payload.familyMemberDetails.filter(
              (member) =>
                member.relationToHead === "AUNT" &&
                member?.marriageStatus === "SINGLE"
            );
            setPotentialBrides(
              wifes.map((wife) => {
                return {
                  label: `${wife?.firstName} ${wife?.middleName} ${wife?.lastName}`,
                  value: wife?.memberId,
                };
              })
            );
          } else if (basicDetails?.relationToHead === "GRAND FATHER") {
            let wifes = result.payload.familyMemberDetails.filter(
              (member) =>
                member.relationToHead === "GRAND MOTHER" &&
                member?.marriageStatus === "SINGLE"
            );
            setPotentialBrides(
              wifes.map((wife) => {
                return {
                  label: `${wife?.firstName} ${wife?.middleName} ${wife?.lastName}`,
                  value: wife?.memberId,
                };
              })
            );
          } else if (basicDetails?.relationToHead === "FATHER") {
            // let wifeMemberId =
            //   result.payload.familyMemberDetails &&
            //   result.payload.familyMemberDetails.filter(
            //     (member) => member.relationToHead === "MOTHER"
            //   )[0].memberId;
            // getWifeMemberDetails(wifeMemberId);
            // formik3.setFieldValue("brideMemberId", wifeMemberId);
            let wifes = result.payload.familyMemberDetails.filter(
              (member) =>
                member.relationToHead === "MOTHER" &&
                member?.marriageStatus === "SINGLE"
            );
            setPotentialBrides(
              wifes.map((wife) => {
                return {
                  label: `${wife?.firstName} ${wife?.middleName} ${wife?.lastName}`,
                  value: wife?.memberId,
                };
              })
            );
          }
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const getWifeMemberDetails = (memberId) => {
    let serviceParams = {
      api: `${API.GET_MEMBER_DETAIL}?memberId=${memberId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          let wifeDetails =
            result?.payload?.basicMemberDetail &&
            result?.payload?.basicMemberDetail;
          setWifeDetails(wifeDetails);
          if (wifeDetails?.baptismName) {
            formik3.setFieldValue("brideFirstName", wifeDetails?.baptismName);
            formik3.setFieldValue("brideMiddleName", "");
            formik3.setFieldValue("brideLastName", "");
          } else {
            formik3.setFieldValue("brideFirstName", wifeDetails?.firstName);
            formik3.setFieldValue("brideMiddleName", wifeDetails?.middleName);
            formik3.setFieldValue("brideLastName", wifeDetails?.lastName);
          }
          formik3.setFieldValue("brideFatherName", wifeDetails?.fatherName);
          formik3.setFieldValue("brideMotherName", wifeDetails?.motherName);
          formik3.setFieldValue("brideDob", new Date(wifeDetails?.dateOfBirth));
          formik3.setFieldValue("brideProfession", wifeDetails?.occupation);

          //
          setBrideConfirmationDone(result?.payload?.basicConfirmationDetail);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // function to fetch all members details in the family end

  useEffect(() => {
    if (show) {
      if (basicDetails?.baptismName) {
        formik2.setFieldValue("groomFirstName", basicDetails?.baptismName);
        formik2.setFieldValue("groomMiddleName", "");
        formik2.setFieldValue("groomLastName", "");
      } else {
        formik2.setFieldValue("groomFirstName", basicDetails?.firstName);
        formik2.setFieldValue("groomMiddleName", basicDetails?.middleName);
        formik2.setFieldValue("groomLastName", basicDetails?.lastName);
      }
      formik2.setFieldValue("groomFatherName", basicDetails?.fatherName);
      formik2.setFieldValue("groomMotherName", basicDetails?.motherName);
      formik2.setFieldValue("groomDob", new Date(basicDetails?.dateOfBirth));
      formik2.setFieldValue("groomProfession", basicDetails?.occupation);
      formik1.setFieldValue(
        "confirmationDate",
        new Date(holyConfirmationDetails?.confirmationDate)
      );
      formik1.setFieldValue("marriagePlace", churchDetails.churchName);
      formik1.setFieldValue(
        "confirmationDateForValidation",
        new Date(
          new Date(holyConfirmationDetails?.confirmationDate).setDate(
            new Date(holyConfirmationDetails?.confirmationDate).getDate() - 1
          )
        )
      );
      if (baptismDetails?.marriageDate) {
        formik1.setFieldValue(
          "marriageDate",
          new Date(baptismDetails?.marriageDate)
        );
      }
      getAllFamilyMembersDetailsApi();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="xl"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Add Marriage Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <div
            id="wizard1"
            className="wizard-demo wizard clearfix"
            role="application"
          >
            <div className="steps clearfix">
              <ul role="tablist">
                <li
                  role="tab"
                  className={
                    currentState === 1
                      ? "current first"
                      : currentState > 1
                      ? "done first"
                      : "first"
                  }
                  aria-disabled="false"
                  aria-selected="true"
                >
                  <a id="wizard1-t-0" href="#" aria-controls="wizard1-p-0">
                    <span className="number">1</span>
                    <span className="title">Step 1</span>
                  </a>
                </li>
                <li
                  role="tab"
                  className={
                    currentState === 2
                      ? "current second"
                      : currentState > 2
                      ? "done second"
                      : "second"
                  }
                  aria-disabled="true"
                  aria-selected="false"
                >
                  <a id="wizard1-t-1" href="#" aria-controls="wizard1-p-1">
                    <span className="number">2</span>{" "}
                    <span className="title">Step 2</span>
                  </a>
                </li>
                <li
                  role="tab"
                  className={
                    currentState === 3
                      ? "current last"
                      : currentState > 3
                      ? "done last"
                      : "last"
                  }
                  aria-disabled="true"
                >
                  <a id="wizard1-t-2" href="#" aria-controls="wizard1-p-2">
                    <span className="number">3</span>{" "}
                    <span className="title">Step 3</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="content clearfix">
              {currentState === 1 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Basic Information
                  </h3>
                  <p className="mb-0">Please enter the below details</p>
                  <hr className="hr" />
                  <MarriageFormStep1 formik={formik1} />
                </div>
              )}
              {currentState === 2 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Groom's Basic Information
                  </h3>
                  <p className="mb-0">Please enter the below details</p>
                  <hr className="hr" />
                  <MarriageFormStep2
                    formik={formik2}
                    basicDetails={basicDetails}
                  />
                </div>
              )}

              {currentState === 3 && (
                <div>
                  <Row className="d-flex justify-content-between">
                    <Col sm={6}>
                      <h3
                        id="wizard1-h-0"
                        tabIndex="-1"
                        className="title current"
                      >
                        Bride's Basic Information
                      </h3>
                      <p className="mb-0">Please enter the below details</p>
                    </Col>
                    <Col sm={6}>
                      <Form.Label
                        className="mt-2 fw-bold text-primary"
                        htmlFor={"potentialWife"}
                      >
                        Select Bride to fetch details
                      </Form.Label>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? "grey" : "#506fd9",
                            borderWidth: 2,
                          }),
                        }}
                        value={selectedWife}
                        id={"potentialWife"}
                        options={potentialBrides}
                        onChange={(e) => {
                          setSelectedWife(e);
                          getWifeMemberDetails(e.value);
                          formik3.setFieldValue("brideMemberId", e.value);
                        }}
                        isSearchable={true}
                        // isDisabled={
                        //   basicDetails.familyHead ||
                        //   basicDetails?.relationToHead === "FATHER"
                        // }
                      />
                    </Col>
                  </Row>
                  {!formik3.values.brideMemberId &&
                    potentialBrides.length === 0 && (
                      <Alert variant="danger" className="my-2">
                        <h6 className="m-0">Brides Details not found.</h6>
                        <p className="m-0">
                          Please Verify if the bride is a part of{" "}
                          {basicDetails?.firstName}'s family.
                        </p>
                      </Alert>
                    )}
                  <hr className="hr" />
                  <MarriageFormStep3
                    formik={formik3}
                    wifeDetails={wifeDetails}
                    brideConfirmationDone={brideConfirmationDone}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              if (!(currentState <= 1)) {
                setCurrentState((prevValue) => prevValue - 1);
              }
            }}
          >
            Previous
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (currentState === 1) {
                formik1.handleSubmit();
              } else if (currentState === 2) {
                formik2.handleSubmit();
              } else if (currentState === 3) {
                if (brideConfirmationDone) {
                  formik3.handleSubmit();
                }
              }
            }}
          >
            {currentState === 3 ? "Save" : "Next"}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(AddMarriageDetailsModal);
