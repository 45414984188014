import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

const SelectInput = ({
  placeholder,
  selectOptions,
  label,
  id,
  setFieldValue,
  keyword,
  value,
  error,
  touched,
  disabled,
  onChange,
  required,
}) => {
  console.log(
    "valuie",
    selectOptions?.filter((option) => option?.value === value)[0]?.value
  );
  return (
    <div>
      {label && (
        <Form.Label className="mt-2 fw-bold" htmlFor={id}>
          {label}
          {required && <span className="text-danger ms-1">*</span>}
        </Form.Label>
      )}
      <Select
        className="d-none d-lg-inline"
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: error ? "red" : "lightgray",
            zIndex: 0,
          }),
        }}
        id={id}
        placeholder={placeholder}
        onChange={(e) => {
          setFieldValue(keyword, e.value);
          if (onChange) {
            onChange();
          }
        }}
        options={selectOptions}
        isSearchable={true}
        value={selectOptions.filter((option) => option.value === value)}
        isDisabled={disabled}
      />
      <Form.Select
        multiple={false}
        className="d-lg-none"
        onChange={(e) => {
          setFieldValue(keyword, e?.target?.value);
        }}
        value={
          value &&
          selectOptions?.filter((option) => option?.value === value)[0]?.value
        }
      >
        <option>Select Option</option>
        {selectOptions?.map((option, i) => {
          return (
            <option key={i} value={option?.value}>
              {option?.label}
            </option>
          );
        })}
      </Form.Select>
      {error && touched && <p className="m-0 text-danger fs-11">* {error}</p>}
    </div>
  );
};

export default SelectInput;
