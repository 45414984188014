import React from "react";
import churchLogo from "../../assets/img/logo-1.png";
import waterMark from "../../assets/img/holy-confirmation.png";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import FontDancingRegular from "../../assets/fonts/dancingscript/DancingScript-Regular.ttf";
import FontMateSCRegular from "../../assets/fonts/matesc/MateSC-Regular.ttf";
import FontRozhaOneRegular from "../../assets/fonts/Rozha_One/RozhaOne-Regular.ttf";
import delhidioceselogo from "../../assets/img/logo-for-cert.png";
import LetterHeadOne from "./letterheads/LetterHeadOne";
import LetterHeadTwo from "./letterheads/LetterHeadTwo";
import LetterHeadThree from "./letterheads/LetterHeadThree";

const addOpacityToColor = (color, opacity = 0.5) => {
  // If color is in hex format (#RRGGBB)
  if (color?.startsWith("#")) {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
  // If color is already in rgb format
  if (color?.startsWith("rgb")) {
    return color.replace("rgb", "rgba").replace(")", `, ${opacity})`);
  }
  // Default fallback
  return color;
};

Font.register({
  family: "Dancing",
  fonts: [
    {
      src: FontDancingRegular,
    },
  ],
});

Font.register({
  family: "Mate",
  fonts: [
    {
      src: FontMateSCRegular,
    },
  ],
});

Font.register({
  family: "Rozha One",
  fonts: [
    {
      src: FontRozhaOneRegular,
    },
  ],
});

const HolyCommunionCertificatePdf = ({
  details,
  churchDetails,
  communionCertConfig,
}) => {
  const dynamicValueTextStyle = {
    ...styles.valueText,
    fontSize: communionCertConfig?.fieldsStyles?.valueFontSize || 12,
    color: communionCertConfig?.fieldsStyles?.valueColor || "#000000",
  };

  const dynamicLabelTextStyle = {
    ...styles.keyText,
    fontSize: communionCertConfig?.fieldsStyles?.labelFontSize || 12,
    color: communionCertConfig?.fieldsStyles?.labelColor || "#000000",
  };

  return (
    <Document>
      <Page
        size="A4"
        style={[
          styles.page,
          { backgroundColor: communionCertConfig?.backgroundColor },
        ]}
      >
        {/* Watermark */}
        {churchDetails?.watermarkUrl && (
          <View
            style={[
              styles.watermarkContainer,
              { opacity: communionCertConfig?.watermarkOpacity },
            ]}
          >
            <Image
              style={{ width: "100%", height: "100%" }}
              src={churchDetails?.watermarkUrl}
            />
          </View>
        )}

        {/* Certificate Content */}
        <View
          style={[
            styles.certContainer,
            {
              backgroundColor: addOpacityToColor(
                communionCertConfig?.backgroundColor
              ),
              zIndex: 0,
            },
          ]}
        >
          <div
            style={
              communionCertConfig?.border?.show && [
                styles.borderOuter,
                {
                  border: `3px solid ${communionCertConfig?.border?.outerBorderColor}`,
                },
              ]
            }
          >
            <div
              style={
                communionCertConfig?.border?.show && [
                  styles.borderInner,
                  {
                    border: `3px solid ${communionCertConfig?.border?.innerborderColor}`,
                  },
                ]
              }
            >
              {/* Header */}
              <View>
                <Text
                  style={[
                    dynamicValueTextStyle,
                    { position: "absolute", top: -10, left: -10, fontSize: 10 },
                  ]}
                >
                  Ref. No:{" "}
                  {details?.altRegReferenceNum
                    ? details?.altRegReferenceNum
                    : details?.regReferenceNum
                    ? details?.regReferenceNum
                    : "-"}
                </Text>

                {communionCertConfig?.letterhead === 1 ? (
                  <LetterHeadOne churchDetails={churchDetails} />
                ) : communionCertConfig?.letterhead === 2 ? (
                  <LetterHeadTwo
                    churchDetails={churchDetails}
                    certConfig={communionCertConfig}
                  />
                ) : (
                  <LetterHeadThree
                    churchDetails={churchDetails}
                    certConfig={communionCertConfig}
                  />
                )}

                <View>
                  <Text
                    style={{
                      textAlign: "center",
                      border: communionCertConfig?.border?.outerBorderColor
                        ? `1.5px solid ${communionCertConfig?.border?.outerBorderColor}`
                        : "2px solid #d4af37",
                      paddingVertical: 2,
                      margin: 0,
                      fontSize: 12,
                      fontFamily: "Mate",
                      // paddingBottom: 8,
                    }}
                  >
                    Extract from the parochial register of{" "}
                    {churchDetails?.churchName}, Archdiocese Of Delhi
                  </Text>
                </View>

                <View>
                  <Text
                    style={{
                      // textTransform: "uppercase",
                      color: "#000",
                      textAlign: "center",
                      marginVertical: 20,
                      fontFamily: "Dancing",
                      fontSize: 32,
                    }}
                  >
                    Holy Communion Certificate
                  </Text>
                </View>

                {/* Body */}
                <View style={styles.content}>
                  {/* Dynamic content here */}

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      {communionCertConfig?.fields?.dateOfCommunion && (
                        <Text style={dynamicLabelTextStyle}>
                          Date of Communion :
                        </Text>
                      )}
                      {communionCertConfig?.fields?.dateOfBaptism && (
                        <Text style={dynamicLabelTextStyle}>
                          Date of Baptism :
                        </Text>
                      )}
                      {communionCertConfig?.fields?.dateOfBirth && (
                        <Text style={dynamicLabelTextStyle}>
                          Date of Birth or Age :
                        </Text>
                      )}
                      {communionCertConfig?.fields?.firstName && (
                        <Text style={dynamicLabelTextStyle}>Name :</Text>
                      )}
                      {communionCertConfig?.fields?.lastName && (
                        <Text style={dynamicLabelTextStyle}>Surname :</Text>
                      )}

                      {communionCertConfig?.fields?.gender && (
                        <Text style={dynamicLabelTextStyle}>Sex :</Text>
                      )}

                      {communionCertConfig?.fields?.fatherName && (
                        <Text style={dynamicLabelTextStyle}>
                          Father's Name :
                        </Text>
                      )}
                      {communionCertConfig?.fields?.motherName && (
                        <Text style={dynamicLabelTextStyle}>
                          Mother's Name :
                        </Text>
                      )}

                      {communionCertConfig?.fields?.mobileNumber && (
                        <Text style={dynamicLabelTextStyle}>Mobile No. :</Text>
                      )}

                      {communionCertConfig?.fields?.address && (
                        <Text style={dynamicLabelTextStyle}>Address :</Text>
                      )}

                      {communionCertConfig?.fields?.placeOfCommunion && (
                        <Text style={dynamicLabelTextStyle}>
                          Place Of Communion :
                        </Text>
                      )}

                      {communionCertConfig?.fields?.ministerName && (
                        <Text style={dynamicLabelTextStyle}>
                          Minister's Name :
                        </Text>
                      )}
                    </View>

                    <View style={{ marginLeft: 20 }}>
                      {communionCertConfig?.fields?.dateOfCommunion && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.holyCommunionDate
                            ? new Date(
                                details?.holyCommunionDate
                              ).toLocaleDateString("en-IN", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              })
                            : "-"}
                        </Text>
                      )}

                      {communionCertConfig?.fields?.dateOfBaptism && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.baptismDate
                            ? new Date(details?.baptismDate).toLocaleDateString(
                                "en-IN",
                                {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                }
                              )
                            : "-"}
                        </Text>
                      )}
                      {communionCertConfig?.fields?.dateOfBirth && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.dob
                            ? new Date(details?.dob).toLocaleDateString(
                                "en-IN",
                                {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                }
                              )
                            : "-"}
                        </Text>
                      )}

                      {communionCertConfig?.fields?.firstName && (
                        <Text style={dynamicValueTextStyle}>
                          {`${details?.firstName} ${details?.middleName}`}
                        </Text>
                      )}
                      {communionCertConfig?.fields?.lastName && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.lastName}
                        </Text>
                      )}

                      {communionCertConfig?.fields?.gender && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.gender ? details?.gender : "-"}
                        </Text>
                      )}

                      {communionCertConfig?.fields?.fatherName && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.fatherName ? details?.fatherName : "-"}
                        </Text>
                      )}
                      {communionCertConfig?.fields?.motherName && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.motherName ? details?.motherName : "-"}
                        </Text>
                      )}

                      {communionCertConfig?.fields?.mobileNumber && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.mobileNum ? details?.mobileNum : "-"}
                        </Text>
                      )}

                      {communionCertConfig?.fields?.address && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.address ? details?.address : "-"}
                        </Text>
                      )}

                      {communionCertConfig?.fields?.placeOfCommunion && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.placeOfCommunion
                            ? details?.placeOfCommunion
                            : "-"}
                        </Text>
                      )}
                      {communionCertConfig?.fields?.ministerName && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.ministerName ? details?.ministerName : "-"}
                        </Text>
                      )}
                    </View>
                  </View>

                  {/* Add more details as needed */}
                </View>
              </View>
              {/* Footer */}
              <View style={styles.footer}>
                <View>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "Mate",
                      textAlign: "center",
                    }}
                  >
                    Fr. {churchDetails?.fatherName}
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#7e7e7e",
                      fontFamily: "Mate",
                      textAlign: "center",
                    }}
                  >
                    {new Date().toLocaleDateString("en-IN", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </Text>
                </View>

                <Text style={{ fontSize: 12, fontFamily: "Mate" }}>Seal</Text>
              </View>
            </div>
          </div>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    // padding: 50,
  },
  watermarkContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0.15, // Adjust opacity as needed
    justifyContent: "center",
    alignItems: "center",
  },
  borderOuter: {
    padding: 5,
    border: "3px solid #58595b",
    height: "100%",
  },
  borderInner: {
    border: "3px double #ce202f",
    padding: 20,
    height: "100%",
    justifyContent: "space-between",
  },
  certContainer: {
    border: 5,
    // borderColor: "#d4af37",
    boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
    padding: 20,
    backgroundColor: "rgb(240, 229, 207, 0.5)",
    height: "100%",
  },
  content: {
    textAlign: "left",
    marginTop: 24,
  },
  footer: {
    marginTop: 100,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  keyText: {
    fontSize: 12,
    color: "#7e7e7e",
    marginBottom: 10,
    fontFamily: "Mate",
  },
  valueText: {
    fontSize: 12,
    color: "#000",
    textTransform: "capitalize",
    fontFamily: "Mate",
    marginBottom: 10,
  },
});

export default HolyCommunionCertificatePdf;
