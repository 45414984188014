import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import delhidioceselogowithoutlabel from "../../../assets/img/delhi-diocese-logo-1.png";

const LetterHeadThree = ({ churchDetails, certConfig }) => {
  const letterheadConfigStyles = certConfig?.letterheadStyles;

  return (
    <>
      <View style={styles.header}>
        <View style={{ marginRight: 0 }}>
          {churchDetails?.logoUrl && (
            <Image
              style={[
                styles.image,
                {
                  height: letterheadConfigStyles?.churchLogoSize,
                  width: letterheadConfigStyles?.churchLogoSize,
                },
              ]}
              src={churchDetails?.logoUrl}
            />
          )}
        </View>

        {/* Right side - Church Details */}
        <View
          style={{
            alignContent: "center",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontSize: letterheadConfigStyles?.dioceseTextStyles?.fontSize,
              textAlign: "center",
              textTransform: "uppercase",
              fontFamily: "Rozha One",
              margin: -6,
              color: letterheadConfigStyles?.dioceseTextStyles?.color,
            }}
          >
            Delhi Catholic Archdiocese
          </Text>

          <Text
            style={{
              fontSize: letterheadConfigStyles?.churchTextStyles?.fontSize,
              fontFamily: "Rozha One",
              textTransform: "uppercase",
              textAlign: "center",
              color: letterheadConfigStyles?.churchTextStyles?.color,
            }}
          >
            {churchDetails?.churchName}
          </Text>

          {/* Horizontal Divider */}
          {/* <View
          style={{
            height: 2,
            backgroundColor: certConfig?.border?.outerBorderColor
              ? certConfig?.border?.outerBorderColor
              : "#d4af37",
            marginVertical: 5,
          }}
        /> */}

          <Text
            style={{
              fontSize:
                letterheadConfigStyles?.churchAddressTextStyles?.fontSize,
              fontFamily: "Rozha One",
              textAlign: "center",
              margin: -4,
              color: letterheadConfigStyles?.churchAddressTextStyles?.color,
            }}
          >
            {churchDetails?.churchAddress?.addressLine1}
          </Text>
        </View>

        <View style={{ marginRight: 0 }}>
          {
            <Image
              style={[
                styles.image,
                {
                  height: letterheadConfigStyles?.dioceseLogoSize,
                  width: letterheadConfigStyles?.dioceseLogoSize,
                },
              ]}
              src={delhidioceselogowithoutlabel}
            />
          }
        </View>
      </View>
      <View
        style={{
          height: 0.1,
          backgroundColor: certConfig?.border?.outerBorderColor
            ? certConfig?.border?.outerBorderColor
            : "#d4af37",
          marginVertical: 5,
        }}
      />
    </>
  );
};

export default LetterHeadThree;

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
    marginBottom: 10,
  },
  image: {
    height: 75,
    width: 75,
    marginRight: 10,
  },
  headerText: {
    textAlign: "center",
    width: "100%",
  },
});
