import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Accordion,
  InputGroup,
  Button,
  Spinner,
} from "react-bootstrap";
import { SketchPicker } from "react-color";
import { Settings, Layout, Type, FileText } from "lucide-react";
import { API, AXIOS_METHOD_TYPES } from "../../../constants/api";
import Swal from "sweetalert2";
import { withAPIRequest } from "../../../HOC/withAPIRequest";

const LetterConfigs = ({ commonAPIRequest, churchId, letterConfig }) => {
  const [loading, setLoading] = useState(false);

  const [config, setConfig] = useState({
    letterhead: 1,
    diocese: {
      fontSize: 24,
      color: "#344fae",
    },
    church: {
      fontSize: 32,
      color: "#912E2D",
    },
    footer: {
      show: false,
    },
  });

  const [activeColorPicker, setActiveColorPicker] = useState(null);

  const handleColorChange = (color, field) => {
    setConfig((prev) => {
      if (field.includes(".")) {
        const [section, key] = field.split(".");
        return {
          ...prev,
          [section]: {
            ...prev[section],
            [key]: color.hex,
          },
        };
      }
      return {
        ...prev,
        [field]: color.hex,
      };
    });
  };

  const ColorPickerButton = ({ color, onClick, label }) => (
    <div className="d-flex align-items-center">
      <span className="me-2">{label}:</span>
      <div
        className="color-preview"
        style={{
          backgroundColor: color,
          width: "36px",
          height: "36px",
          border: "2px solid #dee2e6",
          borderRadius: "4px",
          cursor: "pointer",
          transition: "transform 0.2s",
        }}
        onClick={onClick}
      />
      <span className="ms-2 text-muted">{color}</span>
    </div>
  );

  const handleStyleChange = (value, section, field) => {
    setConfig((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value,
      },
    }));
  };

  // Save Letter Config
  const saveLetterConfigs = () => {
    const serviceParams = {
      api: API.ADD_CONFIGURATION_API,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        configurationDetail: {
          name: "LETTER_CONFIGURATIONS",
          value: config,
          category: "FRONTEND",
        },
        churchId: churchId,
      },
    };

    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire(
            "Success",
            "Letter Configurations Saved Successfully.",
            "success"
          );
        } catch (error) {
          console.log("error", error);
          Swal.fire("Error", "Failed to save configurations.", "error");
        }
      } else {
        setLoading(false);
        Swal.fire("Error", "Failed to save configurations.", "error");
      }
    });
  };

  // Update Letter Config
  const updateLetterConfigs = (id) => {
    const serviceParams = {
      api: API.ADD_CONFIGURATION_API,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        configurationDetail: {
          configId: id,
          name: "LETTER_CONFIGURATIONS",
          value: config,
          category: "FRONTEND",
        },
      },
    };

    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire(
            "Success",
            "Letter Configurations Saved Successfully.",
            "success"
          );
        } catch (error) {
          console.log("error", error);
          Swal.fire("Error", "Failed to save configurations.", "error");
        }
      } else {
        setLoading(false);
        Swal.fire("Error", "Failed to save configurations.", "error");
      }
    });
  };

  // Load existing configs
  useEffect(() => {
    if (letterConfig?.configId) {
      setConfig((prevConfig) => ({
        ...prevConfig,
        ...letterConfig.value,
        diocese: {
          ...prevConfig.diocese,
          ...letterConfig.value.diocese,
        },
        church: {
          ...prevConfig.church,
          ...letterConfig.value.church,
        },
        footer: {
          ...prevConfig.footer,
          ...letterConfig.value.footer,
        },
      }));
    }
  }, [letterConfig]);

  return (
    <div className="p-4">
      <h4 className="mb-4 d-flex align-items-center">
        <Settings className="me-2" />
        Letter Configurations
      </h4>

      <Accordion className="shadow-sm">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <Layout className="me-2" />
            Layout Settings
          </Accordion.Header>
          <Accordion.Body>
            <Row className="g-3">
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Letterhead Style</Form.Label>
                  <Form.Select
                    value={config.letterhead}
                    onChange={(e) =>
                      setConfig((prev) => ({
                        ...prev,
                        letterhead: parseInt(e.target.value),
                      }))
                    }
                  >
                    <option value={1}>Style 1</option>
                    <option value={2}>Style 2</option>
                    <option value={3}>Style 3</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Check
                  type="switch"
                  id="footer-toggle"
                  label="Show Footer"
                  checked={config.footer.show}
                  onChange={() =>
                    setConfig((prev) => ({
                      ...prev,
                      footer: { ...prev.footer, show: !prev.footer.show },
                    }))
                  }
                />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <Type className="me-2" />
            Diocese Typography
          </Accordion.Header>
          <Accordion.Body>
            <Row className="g-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Diocese Font Size</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      value={config.diocese.fontSize}
                      onChange={(e) =>
                        handleStyleChange(
                          parseInt(e.target.value),
                          "diocese",
                          "fontSize"
                        )
                      }
                    />
                    <InputGroup.Text>px</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={6}>
                <ColorPickerButton
                  color={config.diocese.color}
                  onClick={() => setActiveColorPicker("diocese.color")}
                  label="Diocese Text Color"
                />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <FileText className="me-2" />
            Church Typography
          </Accordion.Header>
          <Accordion.Body>
            <Row className="g-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Church Font Size</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      value={config.church.fontSize}
                      onChange={(e) =>
                        handleStyleChange(
                          parseInt(e.target.value),
                          "church",
                          "fontSize"
                        )
                      }
                    />
                    <InputGroup.Text>px</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={6}>
                <ColorPickerButton
                  color={config.church.color}
                  onClick={() => setActiveColorPicker("church.color")}
                  label="Church Text Color"
                />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {activeColorPicker && (
        <div className="color-picker-overlay">
          <div
            className="color-picker-backdrop"
            onClick={() => setActiveColorPicker(null)}
          />
          <div className="color-picker-popover">
            <SketchPicker
              color={
                activeColorPicker.includes(".")
                  ? config[activeColorPicker.split(".")[0]][
                      activeColorPicker.split(".")[1]
                    ]
                  : config[activeColorPicker]
              }
              onChange={(color) => handleColorChange(color, activeColorPicker)}
            />
          </div>
        </div>
      )}

      <style>
        {`
          .color-picker-overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
          }
          .color-picker-backdrop {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
          .color-picker-popover {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1001;
          }
          .color-preview:hover {
            transform: scale(1.1);
          }
        `}
      </style>

      <div className="mt-4 d-flex justify-content-end">
        <Button
          variant="primary"
          onClick={() =>
            letterConfig?.configId
              ? updateLetterConfigs(letterConfig?.configId)
              : saveLetterConfigs()
          }
          disabled={loading}
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
              Saving...
            </>
          ) : (
            "Save Configurations"
          )}
        </Button>
      </div>
    </div>
  );
};

export default withAPIRequest(LetterConfigs);
