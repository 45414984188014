import Lottie from "lottie-react";
import React, { useRef, useState } from "react";
import { Badge, Button, Col, Modal, Nav, Row } from "react-bootstrap";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import DateInput from "../../components/form/DateInput";
import { useFormik } from "formik";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { Link } from "react-router-dom";
import Avatar from "../../components/Avatar";
import { useReactToPrint } from "react-to-print";

function formatDateToYYYYMMDD(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function isSameDateAsToday(inputDate) {
  const today = new Date();

  return (
    inputDate.getFullYear() === today.getFullYear() &&
    inputDate.getMonth() === today.getMonth() &&
    inputDate.getDate() === today.getDate()
  );
}

const BirthdayFilterModal = ({ show, setShow, commonAPIRequest }) => {
  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
    },
    onSubmit: (values) => {
      getAllBirthdaysApi(
        formatDateToYYYYMMDD(values.startDate),
        formatDateToYYYYMMDD(values.endDate)
      );
      //   setShow(false);
      //   formik.resetForm();
    },
  });

  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const contentRef = useRef(null);
  const reactToPrintFn = useReactToPrint({
    contentRef: contentRef,
    pageStyle: `
      @page {
        margin: 40px;
      }
      body {
        margin: 40px;
        padding: 50px;
      }
    `,
  });

  // call api for get all birthdays

  const getAllBirthdaysApi = (startDate, endDate) => {
    let serviceParams = {
      api: `${API.GET_BIRTHDAY}?startDate=${startDate}&endDate=${endDate}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setMembers(result?.payload?.birthdayDetailList);
          setStep(2);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // call api for get all birthdays

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size={step === 1 ? "sm" : "md"}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Birthday Filter
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className=" d-flex align-items-center justify-content-center ">
            <Lottie
              style={{ width: 160, height: 160 }}
              animationData={loadingAnimation}
              loop={true}
            />
            <span style={{ marginLeft: -40 }}>Fetching Birthday's...</span>
          </div>
        ) : (
          <div>
            {step === 1 ? (
              <Row>
                <Col sm={12}>
                  <DateInput
                    label={"Start Date"}
                    placeholder={"Select Start Date"}
                    setFieldValue={formik.setFieldValue}
                    keyword={"startDate"}
                    value={formik.values.startDate}
                    error={formik.errors.startDate}
                    touched={formik.touched.startDate}
                  />
                </Col>
                <Col sm={12}>
                  <DateInput
                    label={"End Date"}
                    placeholder={"Select End Date"}
                    setFieldValue={formik.setFieldValue}
                    keyword={"endDate"}
                    value={formik.values.endDate}
                    error={formik.errors.endDate}
                    touched={formik.touched.endDate}
                  />
                </Col>
                <Col className="mt-3" sm={12}>
                  <Button
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    style={{ width: "100%" }}
                    className=""
                  >
                    <i className="ri-search-line"></i> Search
                  </Button>
                </Col>

                <hr className="hr mt-4" />

                {loading && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="text-center"
                    >
                      <Lottie
                        style={{ width: 120, height: 120 }}
                        animationData={loadingAnimation}
                        loop={true}
                      />
                    </div>

                    <p className="text-center">Loading...</p>
                  </div>
                )}
                {/*  */}

                {/*  */}
                {/* {!loading && members.length === 0 && (
            <div className="mb-5">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="text-center"
              >
                <Lottie
                  style={{ width: 180, height: 180 }}
                  animationData={noSearchAnimation}
                  loop={true}
                />
              </div>
              <h3 className="text-center fw-bold">No Members Found</h3>
              <h5 className="text-center fw-400">
                Please Try With Another Blood Group.
              </h5>
            </div>
          )} */}
              </Row>
            ) : (
              <Row className="p-4 pt-0">
                <div className="d-flex p-0 justify-content-between">
                  <div>
                    <h5 id="section3" className="main-subtitle m-0">
                      All Birthday's From
                    </h5>
                    <p>
                      <span className="fw-bold text-primary text-decoration-underline">
                        {new Date(
                          formik?.values?.startDate
                        ).toLocaleDateString()}{" "}
                        -{" "}
                        {new Date(formik?.values?.endDate).toLocaleDateString()}
                      </span>
                      .
                    </p>
                  </div>
                  <div className="d-flex mb-3 justify-content-end align-items-end">
                    <Button onClick={reactToPrintFn} className="mx-1">
                      <i className="ri-printer-fill"></i>
                    </Button>
                    <Button
                      onClick={() => {
                        setStep(1);
                        setMembers([]);
                      }}
                      className="mx-1"
                    >
                      Reset
                    </Button>
                    {/* <Button>Download</Button> */}
                  </div>
                </div>

                <hr />
                <div className="d-none">
                  <div ref={contentRef}>
                    <h5 id="section3" className="main-subtitle m-0">
                      All Birthday's From
                    </h5>
                    <p>
                      <span className="fw-bold text-primary text-decoration-underline">
                        {new Date(
                          formik?.values?.startDate
                        ).toLocaleDateString()}{" "}
                        -{" "}
                        {new Date(formik?.values?.endDate).toLocaleDateString()}
                      </span>
                      .
                    </p>
                    <ul ref={contentRef}>
                      {members.map((user, index) => (
                        <li className="people-item" key={user.memberId}>
                          <Avatar
                            initial={`${user?.firstName
                              .charAt(0)
                              .toUpperCase()} ${user?.lastName
                              .charAt(0)
                              .toUpperCase()}`}
                          />
                          <div className="people-body">
                            <h6>
                              {user?.firstName} {user?.lastName}{" "}
                              {isSameDateAsToday(
                                new Date(user?.birthdayDate)
                              ) && (
                                <Badge
                                  className="d-inline ms-2 text-white fw-bold"
                                  bg="primary"
                                  pill
                                >
                                  {getAge(user?.dateOfBirth)} Years Old Now
                                </Badge>
                              )}
                            </h6>
                            <span>
                              {new Date(user.birthdayDate).toLocaleDateString(
                                "en-GB",
                                {
                                  year: "numeric",
                                  day: "numeric",
                                  month: "long",
                                }
                              )}
                            </span>
                          </div>
                          <Nav as="nav" className="nav-icon">
                            <Link
                              to={"/admin/member-details"}
                              state={{ memberId: user.memberId, back: true }}
                            >
                              <i className="ri-contacts-line"></i>
                            </Link>
                          </Nav>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <ul
                  style={{ maxHeight: 400, overflowY: "scroll" }}
                  className="people-group"
                >
                  {members.length === 0 ? (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="text-center"
                      >
                        <Lottie
                          style={{ width: 180, height: 180 }}
                          animationData={noSearchAnimation}
                          loop={true}
                        />
                      </div>
                      <h4 className="text-center fw-bold">
                        No Birthday's Found{" "}
                      </h4>
                    </div>
                  ) : (
                    members.map((user, index) => (
                      <li className="people-item" key={user.memberId}>
                        <Avatar
                          initial={`${user?.firstName
                            .charAt(0)
                            .toUpperCase()} ${user?.lastName
                            .charAt(0)
                            .toUpperCase()}`}
                        />
                        <div className="people-body">
                          <h6>
                            {user?.firstName} {user?.lastName}{" "}
                            {isSameDateAsToday(
                              new Date(user?.birthdayDate)
                            ) && (
                              <Badge
                                className="d-inline ms-2 text-white fw-bold"
                                bg="primary"
                                pill
                              >
                                {getAge(user?.dateOfBirth)} Years Old Now
                              </Badge>
                            )}
                          </h6>
                          <span>
                            {new Date(user.birthdayDate).toLocaleDateString(
                              "en-GB",
                              {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }
                            )}
                          </span>
                        </div>
                        <Nav as="nav" className="nav-icon">
                          {/* <Nav.Link href="">
                      <i className="ri-user-star-line"></i>
                    </Nav.Link> */}
                          <Link
                            to={"/admin/member-details"}
                            state={{ memberId: user.memberId, back: true }}
                          >
                            <i className="ri-contacts-line"></i>
                          </Link>
                        </Nav>
                      </li>
                    ))
                  )}
                </ul>
              </Row>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default withAPIRequest(BirthdayFilterModal);
