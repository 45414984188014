import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import delhidioceselogo from "../../../assets/img/logo-for-cert.png";

const LetterHeadOne = ({ churchDetails }) => {
  return (
    <View style={styles.header}>
      {churchDetails?.logoUrl && (
        <Image style={styles.image} src={churchDetails?.logoUrl} />
      )}
      <View
        style={{
          position: "absolute",
          top: -15,
          right: -15,
        }}
      >
        <Image style={{ height: 72, width: "auto" }} src={delhidioceselogo} />
      </View>
      <View style={styles.headerText}>
        <Text
          style={{
            fontSize: 24,
            fontFamily: "Mate",
            marginBottom: 10,
          }}
        >
          {churchDetails?.churchName}
        </Text>
        <Text
          style={{
            fontSize: 12,
            marginVertical: 5,
            fontFamily: "Mate",
          }}
        >
          {churchDetails?.churchAddress?.addressLine1}
        </Text>
      </View>
    </View>
  );
};

export default LetterHeadOne;

const styles = StyleSheet.create({
  header: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginBottom: 10,
  },
  image: {
    maxHeight: 130,
    maxWidth: 130,
    marginRight: 10,
  },
  headerText: {
    textAlign: "center",
    width: "100%",
  },
});
