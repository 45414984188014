import React from "react";
import { Form } from "react-bootstrap";

const TextInput = ({
  label,
  placeholder,
  id,
  setFieldValue,
  keyword,
  value,
  error,
  touched,
  disabled,
  type,
  required,
}) => {
  return (
    <div>
      <Form.Label className="mt-2 fw-bold" htmlFor={id}>
        {label}
        {required && <span className="text-danger ms-1">*</span>}
      </Form.Label>
      <Form.Control
        style={error ? { borderColor: "red" } : {}}
        type={type ? type : "text"}
        id={id}
        rows="3"
        onChange={(e) => setFieldValue(keyword, e.target.value)}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
      ></Form.Control>
      {error && touched && <p className="m-0 fs-11 text-danger">* {error}</p>}
    </div>
  );
};

export default TextInput;
