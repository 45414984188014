import React from "react";
import { Form } from "react-bootstrap";

const TextArea = ({
  label,
  placeholder,
  id,
  setFieldValue,
  keyword,
  value,
  error,
  touched,
  required,
}) => {
  return (
    <div>
      <Form.Label className="mt-2 fw-bold" htmlFor={id}>
        {label}
        {required && <span className="text-danger ms-1">*</span>}
      </Form.Label>
      <Form.Control
        style={error ? { borderColor: "red" } : {}}
        as="textarea"
        id={id}
        rows="3"
        onChange={(e) => setFieldValue(keyword, e.target.value)}
        value={value}
        placeholder={placeholder}
      ></Form.Control>
      {error && touched && <p className="m-0 text-danger">{error}</p>}
    </div>
  );
};

export default TextArea;
