import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import DateInput from "../../components/form/DateInput";
import TextInput from "../../components/form/TextInput";
import TextArea from "../../components/form/TextArea";
import { useSelector } from "react-redux";

const MarriageFormStep1 = ({ formik, outsider }) => {
  const { churchDetails } = useSelector((state) => state?.church);
  const [showAlternateRef, setShowAlternateRef] = useState(false);

  useEffect(() => {
    if (churchDetails?.churchName) {
      formik.setFieldValue("marriagePlace", churchDetails?.churchName);
    }
  }, []);

  const handleCheckboxChange = (e) => {
    setShowAlternateRef(e.target.checked);
    if (!e.target.checked) {
      // Clear the alternate reference number when unchecking
      formik.setFieldValue("alternateRegRefNumber", null);
    }
  };

  return (
    <Row className="g-3">
      {!outsider && (
        <>
          <Col sm={4}>
            <DateInput
              label={"Confirmation Date"}
              placeholder={"e.g. DD-MM-YYYY"}
              setFieldValue={formik.setFieldValue}
              keyword={"confirmationDate"}
              value={formik.values.confirmationDate}
              error={formik.errors.confirmationDate}
              touched={formik.touched.confirmationDate}
              disabled={true}
            />
          </Col>
          <Col sm={4}>
            <DateInput
              label={"Marriage Date"}
              placeholder={"e.g. DD-MM-YYYY"}
              setFieldValue={formik.setFieldValue}
              keyword={"marriageDate"}
              value={formik.values.marriageDate}
              error={formik.errors.marriageDate}
              touched={formik.touched.marriageDate}
            />
            <Form.Check
              type="checkbox"
              label="Not sure if marriage date is correct"
              onChange={(e) => {
                formik.setFieldValue("marriageDateNotSure", e.target.checked);
              }}
              checked={formik.values.marriageDateNotSure}
            />
          </Col>
          <Col sm={4}>
            <TextInput
              label={"Marriage Place"}
              placeholder={"e.g. Noida"}
              setFieldValue={formik.setFieldValue}
              keyword={"marriagePlace"}
              value={formik.values.marriagePlace}
              error={formik.errors.marriagePlace}
              touched={formik.touched.marriagePlace}
            />
          </Col>
        </>
      )}
      {outsider && (
        <>
          <Col sm={4}>
            <DateInput
              label={"Marriage Date"}
              placeholder={"e.g. DD-MM-YYYY"}
              setFieldValue={formik.setFieldValue}
              keyword={"marriageDate"}
              value={formik.values.marriageDate}
              error={formik.errors.marriageDate}
              touched={formik.touched.marriageDate}
            />
            <Form.Check
              type="checkbox"
              label="Not sure if marriage date is correct"
              onChange={(e) => {
                formik.setFieldValue("marriageDateNotSure", e.target.checked);
              }}
              checked={formik.values.marriageDateNotSure}
            />
          </Col>
          <Col sm={8}>
            <TextInput
              label={"Marriage Place"}
              placeholder={"e.g. Noida"}
              setFieldValue={formik.setFieldValue}
              keyword={"marriagePlace"}
              value={formik.values.marriagePlace}
              error={formik.errors.marriagePlace}
              touched={formik.touched.marriagePlace}
            />
          </Col>
        </>
      )}

      <Col sm={4}>
        <DateInput
          label={"Date Of First Banns"}
          placeholder={"e.g. DD-MM-YYYY"}
          setFieldValue={formik.setFieldValue}
          keyword={"firstBannesDate"}
          value={formik.values.firstBannesDate}
          error={formik.errors.firstBannesDate}
          touched={formik.touched.firstBannesDate}
        />
      </Col>
      <Col sm={4}>
        <DateInput
          label={"Date Of Second Banns"}
          placeholder={"e.g. DD-MM-YYYY"}
          setFieldValue={formik.setFieldValue}
          keyword={"secondBannesDate"}
          value={formik.values.secondBannesDate}
          error={formik.errors.secondBannesDate}
          touched={formik.touched.secondBannesDate}
        />
      </Col>
      <Col sm={4}>
        <DateInput
          label={"Date Of Third Banns"}
          placeholder={"e.g. DD-MM-YYYY"}
          setFieldValue={formik.setFieldValue}
          keyword={"thirdBannesDate"}
          value={formik.values.thirdBannesDate}
          error={formik.errors.thirdBannesDate}
          touched={formik.touched.thirdBannesDate}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"1st Witness First Name"}
          placeholder={"e.g. George"}
          setFieldValue={formik.setFieldValue}
          keyword={"firstWitnessFirstName"}
          value={formik.values.firstWitnessFirstName}
          error={formik.errors.firstWitnessFirstName}
          touched={formik.touched.firstWitnessFirstName}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"1st Witness Last Name"}
          placeholder={"e.g. Kerketta"}
          setFieldValue={formik.setFieldValue}
          keyword={"firstWitnessLastName"}
          value={formik.values.firstWitnessLastName}
          error={formik.errors.firstWitnessLastName}
          touched={formik.touched.firstWitnessLastName}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"1st Witness Domicile"}
          placeholder={"e.g. Delhi"}
          setFieldValue={formik.setFieldValue}
          keyword={"firstWitnessDomicile"}
          value={formik.values.firstWitnessDomicile}
          error={formik.errors.firstWitnessDomicile}
          touched={formik.touched.firstWitnessDomicile}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"2nd Witness First Name"}
          placeholder={"e.g. George"}
          setFieldValue={formik.setFieldValue}
          keyword={"secondWitnessFirstName"}
          value={formik.values.secondWitnessFirstName}
          error={formik.errors.secondWitnessFirstName}
          touched={formik.touched.secondWitnessFirstName}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"2nd Witness Last Name"}
          placeholder={"e.g. Kerketta"}
          setFieldValue={formik.setFieldValue}
          keyword={"secondWitnessLastName"}
          value={formik.values.secondWitnessLastName}
          error={formik.errors.secondWitnessLastName}
          touched={formik.touched.secondWitnessLastName}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"2nd Witness Domicile"}
          placeholder={"e.g. Delhi"}
          setFieldValue={formik.setFieldValue}
          keyword={"secondWitnessDomicile"}
          value={formik.values.secondWitnessDomicile}
          error={formik.errors.secondWitnessDomicile}
          touched={formik.touched.secondWitnessDomicile}
        />
      </Col>
      <Col sm={12}>
        <TextInput
          label={"Minister's Name"}
          placeholder={"e.g. Fr. Joseph"}
          setFieldValue={formik.setFieldValue}
          keyword={"ministerName"}
          value={formik.values.ministerName}
          error={formik.errors.ministerName}
          touched={formik.touched.ministerName}
        />
      </Col>

      <Col sm={showAlternateRef ? 4 : 6}>
        <TextInput
          label={"Register Reference Number"}
          placeholder={"Enter Reference Number"}
          setFieldValue={formik.setFieldValue}
          keyword={"registerRefNumber"}
          value={formik.values.registerRefNumber}
          error={formik.errors.registerRefNumber}
          touched={formik.touched.registerRefNumber}
          type={"number"}
        />
      </Col>

      {showAlternateRef && (
        <Col sm={4}>
          <TextInput
            label={"Alternate Reg. Ref. No."}
            setFieldValue={formik.setFieldValue}
            keyword={"alternateRegRefNumber"}
            value={formik.values.alternateRegRefNumber}
            error={formik.errors.alternateRegRefNumber}
            touched={formik.touched.alternateRegRefNumber}
            type="number"
          />
        </Col>
      )}

      <Col sm={showAlternateRef ? 4 : 6}>
        <TextInput
          label={"Book Reference:"}
          setFieldValue={formik.setFieldValue}
          keyword={"bookNumber"}
          value={formik.values.bookNumber}
          error={formik.errors.bookNumber}
          touched={formik.touched.bookNumber}
          type="text"
        />
      </Col>

      <Col sm={12}>
        <div className="mb-3">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="showAlternateRef"
              checked={showAlternateRef}
              onChange={handleCheckboxChange}
            />
            <label className="form-check-label" htmlFor="showAlternateRef">
              I have an alternate reference number which will be shown on the
              certificate
            </label>
          </div>
        </div>
      </Col>

      <Col sm={6}>
        <TextArea
          label={"Impediment"}
          placeholder={"Type Impediment (if any)"}
          setFieldValue={formik.setFieldValue}
          keyword={"impediment"}
          value={formik.values.impediment}
          error={formik.errors.impediment}
          touched={formik.touched.impediment}
        />
      </Col>
      <Col sm={6}>
        <TextArea
          label={"Remark"}
          placeholder={"Type Remark (if any)"}
          setFieldValue={formik.setFieldValue}
          keyword={"remark"}
          value={formik.values.remark}
          error={formik.errors.remark}
          touched={formik.touched.remark}
        />
      </Col>
    </Row>
  );
};

export default MarriageFormStep1;
