import React from "react";
import { Form, Badge } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";

const DateInput = ({
  label,
  id,
  setFieldValue,
  keyword,
  value,
  error,
  touched,
  placeholder,
  disabled,
  uncertain,
  required,
}) => {
  return (
    <div>
      <Form.Label className="mt-2 fw-bold" htmlFor={id}>
        {label}
        {required && <span className="text-danger ms-1">*</span>}
        {uncertain && (
          <Badge style={{ padding: 5 }} className="ms-2 fw-bold" bg="warning">
            Uncertain
          </Badge>
        )}
      </Form.Label>
      <div className="d-none d-lg-inline">
        <ReactDatePicker
          wrapperClassName={error ? "react-datepicker--error" : ""}
          dateFormat="dd-MM-yyyy"
          placeholderText={"DD-MM-YYYY"}
          selected={value}
          onChange={(date) => {
            console.log("datee", date);
            setFieldValue(keyword, date);
          }}
          className="form-control"
          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          // showYearPicker
          yearDropdownItemNumber={100}
          disabled={disabled}
        />
      </div>

      <Form.Control
        className="d-lg-none"
        style={error ? { borderColor: "red" } : {}}
        type={"date"}
        id={id}
        rows="3"
        onChange={(e) => {
          console.log("datee", e.target.value);
          if (e.target.value) {
            setFieldValue(keyword, new Date(e.target.value));
          } else {
            setFieldValue(keyword, "");
          }
        }}
        value={value && value?.toISOString().split("T")[0]}
        placeholder={placeholder}
        disabled={disabled}
      ></Form.Control>
      {error && touched && <p className="m-0 text-danger fs-11">* {error}</p>}
    </div>
  );
};

export default DateInput;
