import React, { useContext, useState } from "react";
import { Badge, Button, Spinner } from "react-bootstrap";
import { withAPIRequest } from "../../../HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES } from "../../../constants/api";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import GenerateHolyCommunionCertificateModal from "../../../modals/holy-communion/GenerateHolyCommunionCertificateModal";
import HolyCommunionLinkCertificateModal from "../../../modals/holy-communion/HolyCommunionLinkCertificateModal";
import EditOutsiderHolyCommunionDetailsModal from "../../../modals/holy-communion/EditOutsiderHolyCommunionDetailsModal";
import warningimg from "../../../assets/img/warning.png";
import CustomTooltip from "../../../components/CustomTooltip";
import Swal from "sweetalert2";
import { AuthContext } from "react-oauth2-code-pkce";
import maleImg from "../../../assets/img/man_delhi_diocese_theme.png";
import femaleImg from "../../../assets/img/women_delhi_diocese_theme.png";
import SecurityPassKeyModal from "../../../modals/settings/SecurityPassKeyModal";

const HolyCommunionListRow = ({
  communion,
  i,
  commonAPIRequest,
  callGetAllHolyCommunion,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentHolyCommunion, setCurrentHolyCommunion] = useState();
  const [generateHolyCommunionModal, setGenerateHolyCommunionModal] =
    useState(false);
  const { churchDetails } = useSelector((state) => state?.church);
  const [loading2, setLoading2] = useState(false);
  const [holyCommunionCertModal, setHolyCommunionCertModal] = useState(false);

  const [editHolyCommunionOursiderModal, setEditHolyCommunionOursiderModal] =
    useState(false);
  const [openPasskeyModal, setOpenPasskeyModal] = useState(false);

  const communionCertConfig = churchDetails?.configurations?.find(
    (config) => config?.name === "COMMUNION_CONFIGURATIONS"
  )?.value;

  const { tokenData } = useContext(AuthContext);
  const deleteCommunionPermission =
    tokenData?.permissions?.includes("DELETE_COMMUNION");

  // call generate pdf certificate api for holy communion start

  // const callGetHolyCommunionCertificateApi = (id) => {
  //   let serviceParams = {
  //     api: `${API.GENERATE_HOLY_COMMUNION_CERTIFICATE}?holyCommunionId=${id}`,
  //     method: AXIOS_METHOD_TYPES.GET,
  //     data: {},
  //   };
  //   setLoading(true);
  //   commonAPIRequest(serviceParams, async (result) => {
  //     if (result) {
  //       setLoading(false);
  //       try {
  //         setCertPdf(result?.payload);
  //       } catch (error) {
  //         console.log("error", error);
  //       }
  //     } else {
  //       setLoading(false);
  //     }
  //   });
  // };

  // call generate pdf certificate api for holy communion end

  const callGetHolyCommunionDetailsForLink = (id) => {
    let serviceParams = {
      api: `${API.HOLY_COMMUNION_DETAILS}?holyCommunionId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading2(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading2(false);
        try {
          // console.log("result", result.payload);
          setCurrentHolyCommunion(result?.payload);
          setHolyCommunionCertModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading2(false);
      }
    });
  };

  const callGetHolyCommunionDetailsForPreview = (id) => {
    let serviceParams = {
      api: `${API.HOLY_COMMUNION_DETAILS}?holyCommunionId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // console.log("result", result.payload);
          setCurrentHolyCommunion(result?.payload);
          setGenerateHolyCommunionModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callGetHolyCommunionDetails = (id) => {
    let serviceParams = {
      api: `${API.HOLY_COMMUNION_DETAILS}?holyCommunionId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // console.log("result", result.payload);
          setCurrentHolyCommunion(result?.payload);
          setEditHolyCommunionOursiderModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Delete a Communion start

  const callDeleteCommunionApi = (id) => {
    let serviceParams = {
      api: `${API.DELETE_COMMUNION_DETAILS}?holyCommunionId=${id}`,
      method: AXIOS_METHOD_TYPES.DELETE,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire({
            title: "Deleted!",
            text: "Communion has been deleted.",
            icon: "success",
          });
          callGetAllHolyCommunion();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Delete a Communion start

  return (
    <tr key={communion?.holyCommunionId}>
      <th scope="row">{i + 1}</th>
      <td className="fw-bold">
        <img
          style={{ height: 36, width: 36 }}
          src={communion?.gender === "Male" ? maleImg : femaleImg}
          className="img-fluid me-2"
          alt="..."
        />
        {communion.memberId ? (
          <Link
            to={"/admin/member-details"}
            state={{
              memberId: communion.memberId,
              back: true,
            }}
          >
            {communion.middleName
              ? `${communion.firstName} ${communion.middleName} ${communion.lastName}`
              : `${communion.firstName} ${communion.lastName}`}
          </Link>
        ) : communion.middleName ? (
          `${communion.firstName} ${communion.middleName} ${communion.lastName}`
        ) : (
          `${communion.firstName} ${communion.lastName}`
        )}
        {communion?.memberId && (
          <Badge className="ms-2" bg="primary" pill>
            <i className="ri-links-line me-1"></i>
            Linked
          </Badge>
        )}
      </td>
      <td
        style={{ height: 55 }}
        className="d-flex align-items-center justify-content-start"
      >
        <span style={{ width: 90 }}>
          {new Date(communion?.holyCommunionDate).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })}
        </span>

        {!communion?.holyCommunionDateAccurate && (
          <CustomTooltip label={"Might be Incorrect"} placement={"top"}>
            <Badge style={{ padding: 5 }} className="ms-1 fw-bold" bg="light">
              <img height={18} src={warningimg} alt="warning" />
            </Badge>
          </CustomTooltip>
        )}
      </td>
      <td>
        {communion?.regReferenceNum ? communion?.regReferenceNum : "-"}{" "}
        {communion?.altRegReferenceNum && `(${communion?.altRegReferenceNum})`}
      </td>
      <td>
        <Button
          disabled={communion?.extHolyCommunion}
          variant="primary"
          onClick={() => {
            if (communionCertConfig?.security?.active) {
              setOpenPasskeyModal(true);
            } else {
              callGetHolyCommunionDetailsForLink(communion?.holyCommunionId);
            }
          }}
        >
          {loading2 ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <i className="ri-download-2-fill"></i>
          )}
        </Button>

        <Button
          className="ms-2"
          disabled={communion?.extHolyCommunion}
          variant="warning"
          onClick={() => {
            if (communion?.memberId) {
              Swal.fire(
                "Update From Profile",
                `Please update Holy Communion Details from the user's profile. As he/she is the member of the parish.`,
                "warning"
              );
            } else {
              callGetHolyCommunionDetails(communion?.holyCommunionId);
            }
          }}
        >
          {loading2 ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <i className="ri-edit-2-fill"></i>
          )}
        </Button>

        {deleteCommunionPermission && (
          <CustomTooltip label={"Delete Communion"} placement={"top"}>
            <Button
              onClick={() =>
                Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    callDeleteCommunionApi(communion.holyCommunionId);
                  }
                })
              }
              variant="danger"
              className="ms-2"
            >
              <i className="ri-delete-bin-6-line"></i>
            </Button>
          </CustomTooltip>
        )}
        {/* <Button
          variant="primary"
          className="ms-2"
          onClick={() => {
            callGetHolyCommunionDetailsForPreview(communion?.holyCommunionId);
          }}
        >
          {loading ? (
            <Spinner animation="border" variant="light" />
          ) : (
            <i className="ri-eye-line"></i>
          )}
        </Button> */}
        {/*  */}
      </td>
      <GenerateHolyCommunionCertificateModal
        setShow={setGenerateHolyCommunionModal}
        show={generateHolyCommunionModal}
        holyCommunionId={communion?.holyCommunionId}
        churchDetails={churchDetails}
        currentHolyCommunion={currentHolyCommunion}
      />
      <HolyCommunionLinkCertificateModal
        show={holyCommunionCertModal}
        setShow={setHolyCommunionCertModal}
        holyCommunionId={communion?.holyCommunionId}
        churchDetails={churchDetails}
        currentHolyCommunion={currentHolyCommunion}
      />
      <EditOutsiderHolyCommunionDetailsModal
        show={editHolyCommunionOursiderModal}
        setShow={setEditHolyCommunionOursiderModal}
        holyCommunionId={communion?.holyCommunionId}
        currentHolyCommunion={currentHolyCommunion}
        callGetAllHolyCommunion={callGetAllHolyCommunion}
      />

      <SecurityPassKeyModal
        show={openPasskeyModal}
        setShow={setOpenPasskeyModal}
        churchDetails={churchDetails}
        onSuccess={() => {
          callGetHolyCommunionDetailsForLink(communion?.holyCommunionId);
        }}
        passkey={communionCertConfig?.security?.passkey}
      />
    </tr>
  );
};

export default withAPIRequest(HolyCommunionListRow);
