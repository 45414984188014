import * as Yup from "yup";

const alphabetsOnly = /^[-a-zA-Z][-a-zA-Z\.\'\(\) ]*$/;

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const dateFormatter = (date) => {
  return new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const ExtHolyConfirmationValidationSchema = Yup.object().shape({
  holyCommunionForValidationDate: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  holyConfirmationDate: Yup.date()
    .min(
      Yup.ref("holyCommunionForValidationDate"),
      "Holy Confirmation date can't be before Holy Communion"
    )
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  // holyConfirmationChurchName: Yup.string().required("Required"),
  // holyConfirmationChurchCity: Yup.string().required("Required"),
});

export const HolyConfirmationValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  // lastName: Yup.string().required("Required"),
  // dob: Yup.date()
  //   .required("Required")
  //   .max(
  //     new Date(),
  //     `Date needs to be on or before ${dateFormatter(new Date())}`
  //   ),
  // dateOfBaptism: Yup.date()
  //   .min(Yup.ref("dob"), "Baptism date can't be before Date Of Birth")
  //   .required("Required")
  //   .max(
  //     new Date(),
  //     `Date needs to be on or before ${dateFormatter(new Date())}`
  //   ),
  // holyCommunionForValidationDate: Yup.date()
  //   .min(
  //     Yup.ref("dateOfBaptism"),
  //     "Holy Communion date can't be before Date Of Baptism"
  //   )
  //   .required("Required")
  //   .max(
  //     new Date(),
  //     `Date needs to be on or before ${dateFormatter(new Date())}`
  //   ),

  confirmationDate: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  // confirmationDate: Yup.date()
  //   .min(
  //     Yup.ref("holyCommunionForValidationDate"),
  //     "Holy Confirmation date can't be before Holy Communion"
  //   )
  //   .required("Required")
  //   .max(
  //     new Date(),
  //     `Date needs to be on or before ${dateFormatter(new Date())}`
  //   ),
  // fatherName: Yup.string()
  //   .required("Required")
  //   .matches(alphabetsOnly, "Only alphabets are allowed."),
  // motherName: Yup.string()
  //   .required("Required")
  //   .matches(alphabetsOnly, "Only alphabets are allowed."),
  gender: Yup.string().required("Required"),
  // address: Yup.string().required("Required"),
  // mobileNum: Yup.string()
  //   .required("Required")
  //   .matches(phoneRegExp, "Phone number is not valid")
  //   .max(10, "Atmost 10 - Digits")
  //   .min(10, "Atleast 10 - Digits"),
  // baptismChurchName: Yup.string().required("Required"),
  // baptismChurchAddress: Yup.string().required("Required"),
  // holyCommunionChurchName: Yup.string().required("Required"),
});

export const EditHolyConfirmationValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  // lastName: Yup.string().required("Required"),
  // dob: Yup.date()
  //   .required("Required")
  //   .max(
  //     new Date(),
  //     `Date needs to be on or before ${dateFormatter(new Date())}`
  //   ),
  // dateOfBaptism: Yup.date()
  //   .min(Yup.ref("dob"), "Baptism date can't be before Date Of Birth")
  //   .required("Required")
  //   .max(
  //     new Date(),
  //     `Date needs to be on or before ${dateFormatter(new Date())}`
  //   ),
  // holyCommunionDate: Yup.date()
  //   .min(
  //     Yup.ref("dateOfBaptism"),
  //     "Holy Communion date can't be before Date Of Baptism"
  //   )
  //   .required("Required")
  //   .max(
  //     new Date(),
  //     `Date needs to be on or before ${dateFormatter(new Date())}`
  //   ),
  // holyCommunionForValidationDate: Yup.date()
  //   .required("Required")
  //   .max(
  //     new Date(),
  //     `Date needs to be on or before ${dateFormatter(new Date())}`
  //   ),
  confirmationDate: Yup.date()
    .min(
      Yup.ref("holyCommunionForValidationDate"),
      "Holy Confirmation date can't be before Holy Communion"
    )
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  // fatherName: Yup.string()
  //   .required("Required")
  //   .matches(alphabetsOnly, "Only alphabets are allowed."),
  // motherName: Yup.string()
  //   .required("Required")
  //   .matches(alphabetsOnly, "Only alphabets are allowed."),
  gender: Yup.string().required("Required"),
  // address: Yup.string().required("Required"),
  // mobileNum: Yup.string()
  //   .required("Required")
  //   .matches(phoneRegExp, "Phone number is not valid")
  //   .max(10, "Atmost 10 - Digits")
  //   .min(10, "Atleast 10 - Digits"),
  // baptismChurchName: Yup.string().required("Required"),
  // baptismChurchAddress: Yup.string().required("Required"),
  // holyCommunionChurchName: Yup.string().required("Required"),
});
