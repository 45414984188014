import React, { useEffect, useState } from "react";
import { Badge, Button, Modal } from "react-bootstrap";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import maleImg from "../../assets/img/man_delhi_diocese_theme.png";
import femaleImg from "../../assets/img/women_delhi_diocese_theme.png";
import { Link } from "react-router-dom";
import CustomTooltip from "../../components/CustomTooltip";
import { FamilyAction } from "../../store/slices/FamilySlice";
import { useDispatch } from "react-redux";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";

const YouthMembersModal = ({ show, setShow, commonAPIRequest }) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const getAllYouthMembersApi = () => {
    let serviceParams = {
      api: `${API.GET_MEMBERS_BY_CATEGORY}?ageCategory=youth`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setMembers(result?.payload);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (show) {
      getAllYouthMembersApi();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size={"lg"}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Youth Members
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: 400, overflow: "scroll" }} className="">
        {loading ? (
          <div className=" d-flex align-items-center justify-content-center ">
            <Lottie
              style={{ width: 160, height: 160 }}
              animationData={loadingAnimation}
              loop={true}
            />
            <span style={{ marginLeft: -40 }}>Loading Members...</span>
          </div>
        ) : members.length === 0 ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <Lottie
                style={{ width: 180, height: 180 }}
                animationData={noSearchAnimation}
                loop={true}
              />
            </div>
            <h4 className="text-center fw-bold">No Youth Members Found </h4>
          </div>
        ) : (
          members.map((member) => (
            <div key={member.memberId}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img
                    style={{ height: 48, width: 48 }}
                    src={member?.gender === "Male" ? maleImg : femaleImg}
                    className="img-fluid m-2"
                    alt="..."
                  />
                  <h6 className="ms-2 mt-2 fw-bold">
                    {member.memberId ? (
                      <Link
                        to={"/admin/member-details"}
                        state={{
                          memberId: member.memberId,
                          back: true,
                        }}
                        className="link-02 fw-bold text-primary"
                      >
                        {member.middleName
                          ? `${member.firstName} ${member.middleName} ${member.lastName}`
                          : `${member.firstName} ${member.lastName}`}
                      </Link>
                    ) : member.middleName ? (
                      `${member.firstName} ${member.middleName} ${member.lastName}`
                    ) : (
                      `${member.firstName} ${member.lastName}`
                    )}
                    {member?.active ? (
                      <Badge className="ms-2 fw-bold p-1" pill bg="success">
                        Active
                      </Badge>
                    ) : (
                      <Badge className="ms-2 fw-bold p-1" pill bg="danger">
                        Inactive
                      </Badge>
                    )}

                    <span className="my-1 ms-2 text-primary fw-bold">
                      (
                      {(() => {
                        const birthDate = new Date(member?.dateOfBirth);
                        const today = new Date();

                        // Calculate age
                        let age = today.getFullYear() - birthDate.getFullYear();

                        // Adjust age if birthday hasn't occurred yet this year
                        const monthDiff =
                          today.getMonth() - birthDate.getMonth();
                        if (
                          monthDiff < 0 ||
                          (monthDiff === 0 &&
                            today.getDate() < birthDate.getDate())
                        ) {
                          age--;
                        }

                        return `${age} years old`;
                      })()}
                      )
                    </span>

                    <div className="d-flex">
                      <span className="my-1">
                        {new Date(member?.dateOfBirth).toLocaleDateString(
                          "en-GB",
                          {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          }
                        )}
                      </span>
                    </div>
                  </h6>
                </div>

                <div className="d-flex">
                  <CustomTooltip label={"View Member Detail"} placement={"top"}>
                    <Link
                      to={"/admin/member-details"}
                      state={{
                        memberId: member.memberId,
                        back: true,
                      }}
                    >
                      <Button
                        className="ms-2"
                        variant="primary"
                        onClick={() => {}}
                      >
                        <i className="ri-user-fill"></i>
                      </Button>
                    </Link>
                  </CustomTooltip>

                  <CustomTooltip label={"View Family Detail"} placement={"top"}>
                    <Link
                      onClick={() => {
                        dispatch(FamilyAction.setFamilyId(member?.familyId));
                        localStorage.setItem(
                          "currentFamilyId",
                          member?.familyId
                        );
                        localStorage.setItem(
                          "currentChurchFamilyId",
                          member?.churchFamilyId
                        );
                      }}
                      state={{
                        familyId: member?.familyId,
                      }}
                      to={"/admin/family-details"}
                    >
                      <Button
                        className="ms-2"
                        variant="primary"
                        onClick={() => {}}
                      >
                        <i className="ri-team-fill"></i>
                      </Button>
                    </Link>
                  </CustomTooltip>
                </div>
              </div>
              <hr className="hr m-0" />
            </div>
          ))
        )}
      </Modal.Body>
    </Modal>
  );
};

export default withAPIRequest(YouthMembersModal);
