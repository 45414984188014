import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import FontDancingRegular from "../../assets/fonts/dancingscript/DancingScript-Regular.ttf";
import FontMateSCRegular from "../../assets/fonts/matesc/MateSC-Regular.ttf";
import FontRozhaOneRegular from "../../assets/fonts/Rozha_One/RozhaOne-Regular.ttf";
import LetterHeadOne from "./letterheads/LetterHeadOne";
import LetterHeadTwo from "./letterheads/LetterHeadTwo";
import LetterHeadThree from "./letterheads/LetterHeadThree";

const addOpacityToColor = (color, opacity = 0.5) => {
  // If color is in hex format (#RRGGBB)
  if (color?.startsWith("#")) {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
  // If color is already in rgb format
  if (color?.startsWith("rgb")) {
    return color.replace("rgb", "rgba").replace(")", `, ${opacity})`);
  }
  // Default fallback
  return color;
};

Font.register({
  family: "Dancing",
  fonts: [
    {
      src: FontDancingRegular,
    },
  ],
});

Font.register({
  family: "Mate",
  fonts: [
    {
      src: FontMateSCRegular,
    },
  ],
});

Font.register({
  family: "Rozha One",
  fonts: [
    {
      src: FontRozhaOneRegular,
    },
  ],
});

const BaptismCertificatePdf = ({
  details,
  churchDetails,
  baptismCertConfig,
}) => {
  // console.log("churchDetails", churchDetails?.logoUrl);

  const dynamicValueTextStyle = {
    ...styles.valueText,
    fontSize: baptismCertConfig?.fieldsStyles?.valueFontSize || 12,
    color: baptismCertConfig?.fieldsStyles?.valueColor || "#000",
  };

  const dynamicLabelTextStyle = {
    ...styles.keyText,
    fontSize: baptismCertConfig?.fieldsStyles?.labelFontSize || 12,
    color: baptismCertConfig?.fieldsStyles?.labelColor || "#000",
  };

  return (
    <Document>
      <Page
        size="A4"
        style={[
          styles.page,
          { backgroundColor: baptismCertConfig?.backgroundColor },
        ]}
      >
        {/* Watermark */}
        {churchDetails?.watermarkUrl && (
          <View
            style={[
              styles.watermarkContainer,
              { opacity: baptismCertConfig?.watermarkOpacity },
            ]}
          >
            <Image
              style={{ width: "100%", height: "100%" }}
              src={churchDetails?.watermarkUrl}
            />
          </View>
        )}
        {/* Certificate Content */}
        <View
          style={[
            styles.certContainer,
            {
              borderColor: baptismCertConfig?.border?.mainBorderColor,
              backgroundColor: addOpacityToColor(
                baptismCertConfig?.backgroundColor
              ),
              zIndex: 0,
            },
          ]}
        >
          <div
            style={
              baptismCertConfig?.border?.show && [
                styles.borderOuter,
                {
                  border: `3px solid ${baptismCertConfig?.border?.outerBorderColor}`,
                },
              ]
            }
          >
            <div
              style={
                baptismCertConfig?.border?.show && [
                  styles.borderInner,
                  {
                    border: `3px solid ${baptismCertConfig?.border?.innerborderColor}`,
                  },
                ]
              }
            >
              {/* Header */}
              <View>
                <Text
                  style={[
                    dynamicValueTextStyle,
                    { position: "absolute", top: -10, left: -10, fontSize: 10 },
                  ]}
                >
                  Ref. No:{" "}
                  {details?.altRegReferenceNum
                    ? details?.altRegReferenceNum
                    : details?.regReferenceNum
                    ? details?.regReferenceNum
                    : "-"}
                </Text>

                {baptismCertConfig?.letterhead === 1 ? (
                  <LetterHeadOne churchDetails={churchDetails} />
                ) : baptismCertConfig?.letterhead === 2 ? (
                  <LetterHeadTwo
                    churchDetails={churchDetails}
                    certConfig={baptismCertConfig}
                  />
                ) : (
                  <LetterHeadThree
                    churchDetails={churchDetails}
                    certConfig={baptismCertConfig}
                  />
                )}

                <View>
                  <Text
                    style={{
                      textAlign: "center",
                      border: baptismCertConfig?.border?.outerBorderColor
                        ? `1.5px solid ${baptismCertConfig?.border?.outerBorderColor}`
                        : "2px solid #d4af37",
                      paddingVertical: 2,
                      margin: 0,
                      fontSize: 12,
                      fontFamily: "Mate",
                      paddingBottom: 8,
                    }}
                  >
                    Extract from the parochial register of{" "}
                    {churchDetails?.churchName}, Archdiocese Of Delhi
                  </Text>
                </View>

                <View>
                  <Text
                    style={{
                      color: "#000",
                      textAlign: "center",
                      marginVertical: 20,
                      fontFamily: "Dancing",
                      fontSize: 32,
                    }}
                  >
                    Certificate Of Baptism
                  </Text>
                </View>

                {/* Body */}
                <View style={styles.content}>
                  {/* Dynamic content here */}

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      {baptismCertConfig?.fields?.dateOfBaptism && (
                        <Text style={dynamicLabelTextStyle}>
                          Date of Baptism :
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.dateOfBirth && (
                        <Text style={dynamicLabelTextStyle}>
                          Date of Birth or Age :
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.gender && (
                        <Text style={dynamicLabelTextStyle}>Sex :</Text>
                      )}
                      {baptismCertConfig?.fields?.firstName && (
                        <Text style={dynamicLabelTextStyle}>Name :</Text>
                      )}
                      {baptismCertConfig?.fields?.lastName && (
                        <Text style={dynamicLabelTextStyle}>Surname :</Text>
                      )}
                      {baptismCertConfig?.fields?.fatherName && (
                        <Text style={dynamicLabelTextStyle}>
                          Father's Name :
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.motherName && (
                        <Text style={dynamicLabelTextStyle}>
                          Mother's Name :
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.fatherNationality && (
                        <Text style={dynamicLabelTextStyle}>
                          Father's Nationality :
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.fatherDomicile && (
                        <Text style={dynamicLabelTextStyle}>
                          His Domicile :
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.fatherProfession && (
                        <Text style={dynamicLabelTextStyle}>
                          His Profession :
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.godFatherName && (
                        <Text style={dynamicLabelTextStyle}>
                          Godfather's Name :
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.godFatherLastName && (
                        <Text style={dynamicLabelTextStyle}>
                          Godfather's Surname :
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.godFatherDomicile && (
                        <Text style={dynamicLabelTextStyle}>
                          His Domicile :
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.godMotherName && (
                        <Text style={dynamicLabelTextStyle}>
                          God Mother's Name :
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.godMotherLastName && (
                        <Text style={dynamicLabelTextStyle}>Her Surname :</Text>
                      )}
                      {baptismCertConfig?.fields?.godMotherDomicile && (
                        <Text style={dynamicLabelTextStyle}>
                          Her Domicile :
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.placeOfBaptism && (
                        <Text style={dynamicLabelTextStyle}>
                          Place Of Baptism :
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.ministerName && (
                        <Text style={dynamicLabelTextStyle}>
                          Minister's Name :
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.holyCommunion && (
                        <Text style={dynamicLabelTextStyle}>
                          Holy Communion :
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.confirmation && (
                        <Text style={dynamicLabelTextStyle}>
                          Confirmation :
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.marriage && (
                        <Text style={dynamicLabelTextStyle}>Marriage :</Text>
                      )}
                    </View>

                    <View style={{ marginLeft: 20 }}>
                      {baptismCertConfig?.fields?.dateOfBaptism && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.baptismDate
                            ? new Date(details?.baptismDate).toLocaleDateString(
                                "en-IN",
                                {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                }
                              )
                            : "-"}
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.dateOfBirth && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.dob
                            ? new Date(details?.dob).toLocaleDateString(
                                "en-IN",
                                {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                }
                              )
                            : "-"}
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.gender && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.gender ? details?.gender : "-"}
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.firstName && (
                        <Text style={dynamicValueTextStyle}>
                          {`${details?.firstName} ${details?.middleName}`}
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.lastName && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.lastName}
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.fatherName && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.fatherName ? details?.fatherName : "-"}
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.motherName && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.motherName ? details?.motherName : "-"}
                        </Text>
                      )}

                      {baptismCertConfig?.fields?.fatherNationality && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.fatherNationality
                            ? details?.fatherNationality
                            : "-"}
                        </Text>
                      )}

                      {baptismCertConfig?.fields?.fatherDomicile && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.fatherDomicile
                            ? details?.fatherDomicile
                            : "-"}
                        </Text>
                      )}

                      {baptismCertConfig?.fields?.fatherProfession && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.fatherProfession
                            ? details?.fatherProfession
                            : "-"}
                        </Text>
                      )}

                      {baptismCertConfig?.fields?.godFatherName && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.godFatherName
                            ? details?.godFatherName
                            : "-"}
                        </Text>
                      )}

                      {baptismCertConfig?.fields?.godFatherLastName && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.godFatherSurName
                            ? details?.godFatherSurName
                            : "-"}
                        </Text>
                      )}

                      {baptismCertConfig?.fields?.godFatherDomicile && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.godFatherDomicile
                            ? details?.godFatherDomicile
                            : "-"}
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.godMotherName && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.godMotherName
                            ? details?.godMotherName
                            : "-"}
                        </Text>
                      )}

                      {baptismCertConfig?.fields?.godMotherLastName && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.godMotherSurName
                            ? details?.godMotherSurName
                            : "-"}
                        </Text>
                      )}

                      {baptismCertConfig?.fields?.godMotherDomicile && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.godMotherDomicile
                            ? details?.godMotherDomicile
                            : "-"}
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.placeOfBaptism && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.placeOfBaptism
                            ? details?.placeOfBaptism
                            : "-"}
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.ministerName && (
                        <Text style={dynamicValueTextStyle}>
                          {details?.ministerName ? details?.ministerName : "-"}
                        </Text>
                      )}

                      {baptismCertConfig?.fields?.holyCommunion && (
                        <Text style={[dynamicValueTextStyle, { width: "75%" }]}>
                          {details?.holyCommunionRemark
                            ? details?.holyCommunionRemark
                            : "-"}
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.confirmation && (
                        <Text style={[dynamicValueTextStyle, { width: "75%" }]}>
                          {details?.confirmationRemark
                            ? details?.confirmationRemark
                            : "-"}
                        </Text>
                      )}
                      {baptismCertConfig?.fields?.marriage && (
                        <Text style={[dynamicValueTextStyle, { width: "75%" }]}>
                          {details?.marriageRemark
                            ? details?.marriageRemark
                            : "-"}
                        </Text>
                      )}
                    </View>
                  </View>

                  <View style={{ marginTop: 8, marginBottom: 8 }}>
                    <Text style={dynamicLabelTextStyle}>Remark :</Text>
                    <Text style={dynamicValueTextStyle}>
                      {details?.remarks ? details?.remarks : "-"}
                    </Text>
                  </View>

                  {/* Add more details as needed */}
                </View>
              </View>
              {/* Footer */}
              <View style={styles.footer}>
                <View>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "Mate",
                      textAlign: "center",
                    }}
                  >
                    Fr. {churchDetails?.fatherName}
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#7e7e7e",
                      fontFamily: "Mate",
                      textAlign: "center",
                    }}
                  >
                    {new Date().toLocaleDateString("en-IN", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </Text>
                </View>

                <Text style={{ fontSize: 12, fontFamily: "Mate" }}>Seal</Text>
              </View>
            </div>
          </div>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    // padding: 50,
  },
  watermarkContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0.15, // Adjust opacity as needed
    justifyContent: "center",
    alignItems: "center",
  },
  borderOuter: {
    padding: 5,
    border: "3px solid #58595b",
    height: "100%",
  },
  borderInner: {
    border: "3px double #ce202f",
    padding: 20,
    height: "100%",
    justifyContent: "space-between",
  },
  certContainer: {
    border: 5,
    borderColor: "#d4af37",
    boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
    padding: 20,
    backgroundColor: "#F0E5CF",
    height: "100%",
  },
  content: {
    textAlign: "left",
    marginTop: 24,
  },
  footer: {
    marginTop: 100,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  keyText: {
    fontSize: 12,
    color: "#7e7e7e",
    marginBottom: 10,
    fontFamily: "Mate",
  },
  valueText: {
    fontSize: 12,
    color: "#000",
    textTransform: "capitalize",
    fontFamily: "Mate",
    marginBottom: 10,
  },
});

export default BaptismCertificatePdf;
