import React, { useState } from "react";
import { Button, Col, Modal, Row, Form, InputGroup } from "react-bootstrap";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import { useFormik } from "formik";
import * as Yup from "yup";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { Eye, EyeOff, ShieldCheck } from "lucide-react";

const PassKeySchema = Yup.object().shape({
  passkey: Yup.string()
    .matches(/^\d{4}$/, "Passkey must be exactly 4 digits")
    .required("Required"),
});

const SecurityPassKeyModal = ({
  show,
  setShow,
  commonAPIRequest,
  churchDetails,
  passkey,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [showPasskey, setShowPasskey] = useState(false);
  const [verificationError, setVerificationError] = useState(null);

  const formik = useFormik({
    initialValues: {
      passkey: "",
    },
    validationSchema: PassKeySchema,
    onSubmit: (values) => {
      setLoading(true);
      setVerificationError(null);

      // Simulate a brief loading state for better UX
      setTimeout(() => {
        setLoading(false);

        // Compare entered passkey with the one from props
        if (values.passkey === passkey) {
          // Passkey matches, call success callback
          if (onSuccess) {
            onSuccess();
            setShow(false);
            formik.resetForm();
            setVerificationError(null);
          }
        } else {
          // Passkey doesn't match, show error
          setVerificationError("Invalid passkey. Please try again.");
        }
      }, 500);
    },
  });

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        formik.resetForm();
        setVerificationError(null);
      }}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          <ShieldCheck className="me-2" size={20} />
          Security Verification
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 200 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <div>
            <div className="alert alert-primary text-center mb-4" role="alert">
              <p className="mb-0">
                Please enter your 4-digit security passkey to proceed
              </p>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <Row className="justify-content-center">
                <Col sm="12" md="12">
                  <Form.Group className="mb-3">
                    <Form.Label>Security Passkey</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={showPasskey ? "text" : "password"}
                        id="passkey"
                        name="passkey"
                        placeholder="Enter 4-digit passkey"
                        value={formik.values.passkey}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={
                          formik.touched.passkey && formik.errors.passkey
                        }
                        maxLength={4}
                      />
                      <Button
                        variant="primary"
                        onClick={() => setShowPasskey(!showPasskey)}
                      >
                        {showPasskey ? <EyeOff size={18} /> : <Eye size={18} />}
                      </Button>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.passkey}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            </form>

            {verificationError && (
              <div className="alert alert-danger text-center mb-3">
                {verificationError}
              </div>
            )}
          </div>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
              formik.resetForm();
              setVerificationError(null);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={formik.handleSubmit}
            disabled={!(formik.isValid && formik.dirty)}
          >
            Verify
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(SecurityPassKeyModal);
