import React, { useContext, useState } from "react";
import { Badge, Button, Spinner } from "react-bootstrap";
import CustomTooltip from "../../../components/CustomTooltip";
import { API, AXIOS_METHOD_TYPES } from "../../../constants/api";
import { withAPIRequest } from "../../../HOC/withAPIRequest";
import { ThreeCircles } from "react-loader-spinner";
import { Link } from "react-router-dom";
import MarriageLinkCertificateModal from "../../../modals/marriage/MarriageLinkCertificateModal";
import GenerateMarriageCertificateModal from "../../../modals/marriage/GenerateMarriageCertificateModal";
import { useDispatch, useSelector } from "react-redux";
import EditOutsiderMarriageDetailsModal from "../../../modals/marriage/EditOutsiderMarriageDetailsModal";
import Swal from "sweetalert2";
import warningimg from "../../../assets/img/warning.png";
import { AuthContext } from "react-oauth2-code-pkce";

const MarriageListRow = ({
  marriage,
  i,
  commonAPIRequest,
  callGetAllMarriage,
}) => {
  const [certPdf, setCertPdf] = useState();
  const [loading, setLoading] = useState(false);
  const [currentMarriage, setCurrentMarriage] = useState();
  const [marriageLinkModal, setMarriageLinkModal] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [generateMarriageModal, setGenerateMarriageModal] = useState(false);
  const { churchDetails } = useSelector((state) => state?.church);
  const [editMarriageOutsiderModal, setEditMarriageOutsiderModal] =
    useState(false);

  const { tokenData } = useContext(AuthContext);
  const deleteMarriagePermission =
    tokenData?.permissions?.includes("DELETE_MARRIAGE");

  // call generate pdf certificate api for marriage start

  // const callGetMarriageCertificateApi = (id) => {
  //   let serviceParams = {
  //     api: `${API.GENERATE_MARRIAGE_CERTIFICATE}?marriageId=${id}`,
  //     method: AXIOS_METHOD_TYPES.GET,
  //     data: {},
  //   };
  //   setLoading(true);
  //   commonAPIRequest(serviceParams, async (result) => {
  //     if (result) {
  //       setLoading(false);
  //       try {
  //         setCertPdf(result?.payload);
  //       } catch (error) {
  //         console.log("error", error);
  //       }
  //     } else {
  //       setLoading(false);
  //     }
  //   });
  // };

  // call generate pdf certificate api for marriage end

  // call generate pdf certificate api for baptism end

  const callGetMarriageDetailsForLink = (id) => {
    let serviceParams = {
      api: `${API.MARRIAGE_DETAILS}?marriageId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading2(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading2(false);
        try {
          // console.log("result", result.payload);
          setCurrentMarriage(result?.payload);
          setMarriageLinkModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading2(false);
      }
    });
  };

  const callGetMarriageDetailsForPreview = (id) => {
    let serviceParams = {
      api: `${API.MARRIAGE_DETAILS}?marriageId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // console.log("result", result.payload);
          setCurrentMarriage(result?.payload);
          setGenerateMarriageModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callGetMarriageDetails = (id) => {
    let serviceParams = {
      api: `${API.MARRIAGE_DETAILS}?marriageId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // console.log("result", result.payload);
          setCurrentMarriage(result?.payload);
          setEditMarriageOutsiderModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Delete a Marriage start

  const callDeleteMarriagenApi = (id) => {
    let serviceParams = {
      api: `${API.DELETE_MARRIAGE}?marriageId=${id}`,
      method: AXIOS_METHOD_TYPES.DELETE,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire({
            title: "Deleted!",
            text: "Marriage has been deleted.",
            icon: "success",
          });
          callGetAllMarriage();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Delete a Marriage start

  return (
    <tr key={marriage?.marriageId}>
      <th scope="row">{i + 1}</th>
      <td>
        {marriage.groomMemberId ? (
          <Link
            className="fw-bold"
            to={"/admin/member-details"}
            state={{
              memberId: marriage.groomMemberId,
              back: true,
            }}
          >
            {marriage?.groomMiddleName
              ? `${marriage?.groomFirstName} ${marriage?.groomMiddleName} ${marriage?.groomLastName}`
              : `${marriage?.groomFirstName} ${marriage?.groomLastName}`}
          </Link>
        ) : marriage?.groomMiddleName ? (
          `${marriage?.groomFirstName} ${marriage?.groomMiddleName} ${marriage?.groomLastName}`
        ) : (
          `${marriage?.groomFirstName} ${marriage?.groomLastName}`
        )}
        {!marriage?.brideMemberId && marriage?.groomMemberId && (
          <Badge className="ms-2" bg="secondary" pill>
            Non Member
          </Badge>
        )}
      </td>
      <td>
        {marriage.brideMemberId ? (
          <Link
            className="fw-bold"
            to={"/admin/member-details"}
            state={{
              memberId: marriage.brideMemberId,
              back: true,
            }}
          >
            {marriage?.brideMiddleName
              ? `${marriage?.brideFirstName} ${marriage?.brideMiddleName} ${marriage?.brideLastName}`
              : `${marriage?.brideFirstName} ${marriage?.brideLastName}`}
          </Link>
        ) : marriage?.brideMiddleName ? (
          `${marriage?.brideFirstName} ${marriage?.brideMiddleName} ${marriage?.brideLastName}`
        ) : (
          `${marriage?.brideFirstName} ${marriage?.brideLastName}`
        )}
      </td>
      <td
        style={{ height: 55 }}
        className="d-flex align-items-center justify-content-start"
      >
        <span style={{ width: 90 }}>
          {new Date(marriage?.marriageDate).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })}
        </span>
        {!marriage?.marriageDateAccurate && (
          <CustomTooltip label={"Might be Incorrect"} placement={"top"}>
            <Badge style={{ padding: 5 }} className="ms-1 fw-bold" bg="light">
              <img height={18} src={warningimg} alt="warning" />
            </Badge>
          </CustomTooltip>
        )}
      </td>
      <td>
        {marriage?.active ? (
          <Badge className="ms-2 fw-bold" bg="success" pill>
            Married
          </Badge>
        ) : (
          <Badge className="ms-2 fw-bold" bg="danger" pill>
            Annulled
          </Badge>
        )}
      </td>
      <td>{marriage?.regReferenceNum ? marriage?.regReferenceNum : "-"}</td>
      <td>
        <Button
          disabled={!marriage?.active}
          variant="primary"
          onClick={() => {
            callGetMarriageDetailsForLink(marriage?.marriageId);
          }}
        >
          {loading2 ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <i className="ri-download-2-fill"></i>
          )}
        </Button>

        <Button
          className="ms-2"
          disabled={!marriage?.active}
          variant="warning"
          onClick={() => {
            if (marriage?.groomMemberId || marriage?.brideMemberId) {
              Swal.fire(
                "Update From Profile",
                `Please update Marriage Details from the user's respective profile. As he/she is the member of the parish.`,
                "warning"
              );
            } else {
              callGetMarriageDetails(marriage?.marriageId);
            }
          }}
        >
          {loading2 ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <i className="ri-edit-2-fill"></i>
          )}
        </Button>

        {deleteMarriagePermission && (
          <CustomTooltip label={"Delete Marriage"} placement={"top"}>
            <Button
              onClick={() =>
                Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    callDeleteMarriagenApi(marriage.marriageId);
                  }
                })
              }
              variant="danger"
              className="ms-2"
            >
              <i className="ri-delete-bin-6-line"></i>
            </Button>
          </CustomTooltip>
        )}

        {/* <Button
          variant="primary"
          className="ms-2"
          onClick={() => {
            callGetMarriageDetailsForPreview(marriage?.marriageId);
          }}
        >
          {loading ? (
            <Spinner animation="border" variant="light" />
          ) : (
            <i className="ri-eye-line"></i>
          )}
        </Button> */}
      </td>
      <MarriageLinkCertificateModal
        show={marriageLinkModal}
        setShow={setMarriageLinkModal}
        currentMarriage={currentMarriage}
        marriageId={marriage?.marriageId}
        churchDetails={churchDetails}
      />
      <GenerateMarriageCertificateModal
        setShow={setGenerateMarriageModal}
        show={generateMarriageModal}
        marriageId={marriage?.marriageId}
        churchDetails={churchDetails}
        currentMarriage={currentMarriage}
      />
      <EditOutsiderMarriageDetailsModal
        show={editMarriageOutsiderModal}
        setShow={setEditMarriageOutsiderModal}
        currentMarriage={currentMarriage}
        callGetAllMarriage={callGetAllMarriage}
      />
    </tr>
  );
};

export default withAPIRequest(MarriageListRow);
