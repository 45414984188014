// SelectLetterHeadModal.js

import React from "react";
import { Modal, ListGroup } from "react-bootstrap";
import delhidioceselogo from "../../assets/img/delhi-diocese-logo-1.png";
import delhidioceselogoLabel from "../../assets/img/logo-for-cert.png";

const SelectLetterHeadModal = ({
  show,
  setShow,
  selectLetterhead,
  churchDetails,
  letterConfigs,
}) => {
  const letterheadContent1 =
    "<br /> <br /> <br /> <br /> <br /> <br />  <br /> <br /> <br />";
  const letterheadContentWithLogo1 = `
        <div>
         <div  style='display:flex; align-items: center; width: 100%; justify-content: center'>
              <img style='height: 120px; width: 120px;' src='${churchDetails?.logoUrl}' />
               <img style='height: 120px; width: 120px;' src='${delhidioceselogo}' />
          </div>

           <div style='font-size: 24px; text-transform: uppercase; font-family: "Rozha One", serif; color: ${letterConfigs?.diocese?.color}; text-align: center'>Delhi Catholic  Archdiocese</div>

            <div style='font-size: 32px; text-transform: uppercase; text-align: center; font-family: "Rozha One", serif; color: ${letterConfigs?.church?.color};line-height: 1'>${churchDetails?.churchName}</div>

            <div style='font-size: 16px; text-align: center;  font-family: "Rozha One", serif;'>${churchDetails?.churchAddress?.addressLine1}</div>
            <hr style='margin-top: 12px' />
        </div>
        <br /> <br /> <br />`;

  const letterheadContentWithLogo2 = `
        <div style='display:flex; align-items: center; justify-content: center;'>
          <img style='height: 120px; width: 120px;' src='${churchDetails?.logoUrl}' />

          <div style='width: 80%; margin-left: 8px'>
           <div style='font-size: 24px; text-transform: uppercase; font-family: "Rozha One", serif; color: ${letterConfigs?.diocese?.color}; line-height: 1'>Delhi Catholic  Archdiocese</div>
            <div style='font-size: 28px; text-transform: uppercase;  font-family: "Rozha One", serif; color: ${letterConfigs?.church?.color};'>${churchDetails?.churchName}</div>
            <hr style='margin-top: 0' />
             <div style='font-size: 14px; font-family: "Rozha One", serif;'>${churchDetails?.churchAddress?.addressLine1}</div>
          </div>
          <img style='height: 100px; width: 100px; position: absolute; right: 10px; top: 10px' src='${delhidioceselogo}' />
        </div>
         <hr />
        <br /> <br /> <br />`;

  const letterheadContentWithLogo3 = `
        <div style='display:flex; align-items: center; justify-content: center;'>
          <img style='height: 120px; width: 120px;' src='${churchDetails?.logoUrl}' />

          <div style='width: 80%; margin-left: 8px;'>
            <div style='font-size: 24px; text-transform: uppercase; text-align: center; font-family: "Rozha One", serif; color: ${letterConfigs?.diocese?.color};'>Delhi Catholic  Archdiocese</div>
            <div style='font-size: 32px; text-transform: uppercase; text-align: center; font-family: "Rozha One", serif; color: ${letterConfigs?.church?.color};line-height: 1'>${churchDetails?.churchName}</div>
             <div style='font-size: 14px; text-align: center;  font-family: "Rozha One", serif;'>${churchDetails?.churchAddress?.addressLine1}</div>
          </div>
          <img style='height: 120px; width: 120px;' src='${delhidioceselogo}' />
        </div>
          <hr />
        <br /> <br /> <br />`;

  const letterheadContentWithLogo4 = `
        <div>
         <div  style='display:flex; align-items: center; width: 100%; justify-content: center'>
              <img style='height: 120px; width: 120px;' src='${churchDetails?.logoUrl}' />
              
          </div>
            <div style='font-size: 32px; text-transform: uppercase; text-align: center; font-family: "Rozha One", serif; color: ${letterConfigs?.church?.color};line-height: 1'>${churchDetails?.churchName}</div>

            <div style='font-size: 16px; text-align: center;  font-family: "Rozha One", serif;'>${churchDetails?.churchAddress?.addressLine1}</div>

            <hr style='margin-top: 12px' />
            <img style='height: 100px; width: 100px; position: absolute; right: 20px; top: 20px' src='${delhidioceselogoLabel}' />

        </div>
        <br /> <br /> <br />`;

  const letterheadContentWithLogo5 = `
  <div style='position: relative;'>
    <div style='text-align: center;'>
      <div style='font-size: 36px; text-transform: uppercase; color: #5a67ad; font-family: "Rozha One", serif;'>${
        churchDetails?.churchName
      }</div>
      <div style='font-size: 22px; color: #5a67ad; font-family: "Rozha One", serif;'>(ARCHDIOCESE OF AGRA)</div>
    </div>
    
    <div style='display: flex; justify-content: space-between; align-items: center; margin-top: 0px;'>
      <div style='flex: 1; text-align: left; color: #5a67ad; font-size: 14px;'>
        Parish Priest - ${churchDetails?.priestPhone || "9536427288"}
        <br/>
        Tel: ${churchDetails?.churchPhone || "0120-2507412"} / ${
          churchDetails?.alternatePhone || "8587051339"
        }
      </div>
      
      <div style='flex: 1; text-align: center;'>
        <img style='height: 100px; width: 100px;' src='${
          churchDetails?.logoUrl
        }' />
      </div>
      
      <div style='flex: 1; text-align: right; color: #5a67ad; font-size: 14px;'>
        ${
          churchDetails?.churchAddress?.addressLine1 || "A-71/A, SECTOR-34"
        }<br/>
        ${churchDetails?.churchAddress?.city || "NOIDA"}, ${
          churchDetails?.churchAddress?.state || "U.P."
        }, ${churchDetails?.churchAddress?.country || "INDIA"}<br/>
        PIN. - ${churchDetails?.churchAddress?.pinCode || "201307"}
      </div>
    </div>
    
    <hr style='margin-top: 10px; border-top: 1px solid #5a67ad;' />
  </div>
  <br /><br /><br />`;

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Select a Letterhead</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          <ListGroup.Item
            action
            onClick={() => selectLetterhead(letterheadContentWithLogo1)}
          >
            Letterhead 1
          </ListGroup.Item>
          <ListGroup.Item
            action
            onClick={() => selectLetterhead(letterheadContentWithLogo2)}
          >
            Letterhead 2
          </ListGroup.Item>
          <ListGroup.Item
            action
            onClick={() => selectLetterhead(letterheadContentWithLogo3)}
          >
            Letterhead 3
          </ListGroup.Item>

          <ListGroup.Item
            action
            onClick={() => selectLetterhead(letterheadContentWithLogo4)}
          >
            Letterhead 4
          </ListGroup.Item>

          <ListGroup.Item
            action
            onClick={() => selectLetterhead(letterheadContentWithLogo5)}
          >
            Letterhead 5
          </ListGroup.Item>
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export default SelectLetterHeadModal;
