import React, { useEffect, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import DateInput from "../../components/form/DateInput";
import SelectInput from "../../components/form/SelectInput";

const MarriageFormStep3 = ({
  formik,
  wifeDetails,
  brideConfirmationDone,
  outsider,
}) => {
  const [baptismName, setBaptismName] = useState(!outsider);

  useEffect(() => {
    if (baptismName) {
      if (wifeDetails?.baptismName) {
        formik.setFieldValue("brideFirstName", wifeDetails?.baptismName);
        formik.setFieldValue("brideMiddleName", "");
        formik.setFieldValue("brideLastName", "");
      }
    } else {
      formik.setFieldValue("brideFirstName", wifeDetails?.firstName);
      formik.setFieldValue(
        "brideMiddleName",
        wifeDetails?.middleName ? wifeDetails?.middleName : ""
      );
      formik.setFieldValue(
        "brideLastName",
        wifeDetails?.lastName ? wifeDetails?.lastName : ""
      );
    }
  }, [baptismName]);

  return (
    <Row className="g-3">
      {wifeDetails && !brideConfirmationDone && (
        <Col sm={12}>
          <Alert variant={"danger"}>
            Holy Confirmation details not found for{" "}
            <span className="fw-bold">
              {wifeDetails?.firstName} {wifeDetails?.middleName}{" "}
              {wifeDetails?.lastName}
            </span>
          </Alert>
        </Col>
      )}
      <Col sm={!baptismName ? 4 : 12}>
        <TextInput
          label={baptismName ? "Bride's Baptism Name" : "Bride's First Name"}
          placeholder={"e.g. George"}
          setFieldValue={formik.setFieldValue}
          keyword={"brideFirstName"}
          value={formik.values.brideFirstName}
          error={formik.errors.brideFirstName}
          touched={formik.touched.brideFirstName}
          disabled={outsider ? false : true}
        />
        {wifeDetails?.baptismName && (
          <Form.Check
            type="switch"
            className="mt-1"
            checked={baptismName}
            label="Use Baptism Name"
            onChange={(e) => {
              setBaptismName(e.target.checked);
            }}
          />
        )}
      </Col>
      {!baptismName && (
        <Col sm={4}>
          <TextInput
            label={"Bride's Middle Name"}
            setFieldValue={formik.setFieldValue}
            keyword={"brideMiddletName"}
            value={formik.values.brideMiddletName}
            error={formik.errors.brideMiddletName}
            touched={formik.touched.brideMiddletName}
            disabled={!outsider}
          />
        </Col>
      )}
      {!baptismName && (
        <Col sm={4}>
          <TextInput
            label={"Bride's Last Name"}
            placeholder={"e.g. Watts"}
            setFieldValue={formik.setFieldValue}
            keyword={"brideLastName"}
            value={formik.values.brideLastName}
            error={formik.errors.brideLastName}
            touched={formik.touched.brideLastName}
            disabled={!outsider}
          />
        </Col>
      )}
      <Col sm={6}>
        <TextInput
          label={"Bride's Father's Name"}
          placeholder={"e.g. James Watts"}
          setFieldValue={formik.setFieldValue}
          keyword={"brideFatherName"}
          value={formik.values.brideFatherName}
          error={formik.errors.brideFatherName}
          touched={formik.touched.brideFatherName}
          disabled={wifeDetails?.fatherName ? true : false}
        />
      </Col>
      <Col sm={6}>
        <TextInput
          label={"Bride's Mother's Name"}
          placeholder={"e.g. Julia Watts"}
          setFieldValue={formik.setFieldValue}
          keyword={"brideMotherName"}
          value={formik.values.brideMotherName}
          error={formik.errors.brideMotherName}
          touched={formik.touched.brideMotherName}
          disabled={wifeDetails?.motherName ? true : false}
        />
      </Col>
      <Col sm={12}>
        <DateInput
          label={"Bride's Date Of Birth"}
          placeholder={"e.g. DD-MM-YYYY"}
          setFieldValue={formik.setFieldValue}
          keyword={"brideDob"}
          value={formik.values.brideDob}
          error={formik.errors.brideDob}
          touched={formik.touched.brideDob}
          disabled={wifeDetails?.dateOfBirth ? true : false}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"Bride's Nationality"}
          placeholder={"e.g. Indian"}
          setFieldValue={formik.setFieldValue}
          keyword={"brideNationality"}
          value={formik.values.brideNationality}
          error={formik.errors.brideNationality}
          touched={formik.touched.brideNationality}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"Bride's Domicile"}
          placeholder={"e.g. Delhi"}
          setFieldValue={formik.setFieldValue}
          keyword={"brideDomicile"}
          value={formik.values.brideDomicile}
          error={formik.errors.brideDomicile}
          touched={formik.touched.brideDomicile}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"Bride's Profession"}
          placeholder={"e.g. Service"}
          setFieldValue={formik.setFieldValue}
          keyword={"brideProfession"}
          value={formik.values.brideProfession}
          error={formik.errors.brideProfession}
          touched={formik.touched.brideProfession}
          disabled={wifeDetails?.occupation ? true : false}
        />
      </Col>
      <Col sm={12}>
        <SelectInput
          label={"Bride's Marital Status"}
          placeholder={"e.g. SINGLE"}
          selectOptions={[
            { value: "SINGLE", label: "SINGLE" },
            { value: "DIVORCE", label: "DIVORCE" },
            { value: "WIDOWER", label: "WIDOWER" },
            { value: "SPINSTER", label: "SPINSTER" },
          ]}
          setFieldValue={formik.setFieldValue}
          keyword={"brideMarriageStatus"}
          value={formik.values.brideMarriageStatus}
          error={formik.errors.brideMarriageStatus}
          touched={formik.touched.brideMarriageStatus}
        />
        <p className="text-muted m-0">Marital Status of bride up until now.</p>
      </Col>
      {!(
        formik.values.brideMarriageStatus === "SINGLE" ||
        formik.values.brideMarriageStatus === "SPINSTER" ||
        formik.values.brideMarriageStatus === ""
      ) && (
        <Col sm={12}>
          <TextInput
            label={"Bride's Previous Husband Name (Optional)"}
            placeholder={"e.g. Mike"}
            setFieldValue={formik.setFieldValue}
            keyword={"previousHusbandName"}
            value={formik.values.previousHusbandName}
            error={formik.errors.previousHusbandName}
            touched={formik.touched.previousHusbandName}
          />
        </Col>
      )}
    </Row>
  );
};

export default MarriageFormStep3;
