import React from "react";
import { Col, Form, Row } from "react-bootstrap";

export const DashboardUserPermission = ({
  userPermission,
  setUserPermission,
}) => {
  const dashboardPermissions = [
    "READ_DASHBOARD",
    "READ_BIRTHDAY",
    "READ_ANNIVERSARY",
  ];

  // Check if all dashboard permissions are selected
  const allSelected = dashboardPermissions.every((permission) =>
    userPermission?.includes(permission)
  );

  // Check if some but not all permissions are selected
  const someSelected =
    dashboardPermissions.some((permission) =>
      userPermission?.includes(permission)
    ) && !allSelected;

  // Handle toggle all permissions
  const handleToggleAll = (checked) => {
    if (checked) {
      // Add all dashboard permissions that aren't already included
      const newPermissions = [...userPermission];
      dashboardPermissions.forEach((permission) => {
        if (!newPermissions.includes(permission)) {
          newPermissions.push(permission);
        }
      });
      setUserPermission(newPermissions);
    } else {
      // Remove all dashboard permissions
      setUserPermission(
        userPermission?.filter(
          (permission) => !dashboardPermissions.includes(permission)
        )
      );
    }
  };

  // Handle individual permission toggle
  const handleTogglePermission = (permission, checked) => {
    if (checked) {
      setUserPermission((prev) => [...prev, permission]);
    } else {
      setUserPermission((prev) => prev.filter((p) => p !== permission));
    }
  };

  return (
    <div className="permission-section p-3 mb-3 bg-light rounded border">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center">
          <Form.Check
            id="toggle-all-dashboard"
            type="checkbox"
            checked={allSelected}
            indeterminate={someSelected}
            onChange={(e) => handleToggleAll(e.target.checked)}
            className="me-3"
          />
          <p className="fw-bold text-primary mb-0 fs-5">
            Dashboard Access Permission
          </p>
        </div>
        <div className="badge bg-primary text-white">
          {
            userPermission?.filter((p) => dashboardPermissions.includes(p))
              .length
          }{" "}
          of {dashboardPermissions.length} enabled
        </div>
      </div>

      <Row className="g-3">
        {[
          {
            id: "READ_DASHBOARD",
            label: "Read Dashboard",
            icon: "bi bi-speedometer2",
          },
          { id: "READ_BIRTHDAY", label: "Read Birthday", icon: "bi bi-gift" },
          {
            id: "READ_ANNIVERSARY",
            label: "Read Anniversary",
            icon: "bi bi-calendar-heart",
          },
        ].map((permission) => (
          <Col sm={4} key={permission.id}>
            <div className="permission-card p-2 rounded border d-flex align-items-center">
              <Form.Check
                id={`perm-${permission.id}`}
                onChange={(e) =>
                  handleTogglePermission(permission.id, e.target.checked)
                }
                checked={userPermission?.includes(permission.id)}
                type="switch"
                className="me-2"
              />
              <div className="d-flex align-items-center">
                <i className={`${permission.icon} me-2 text-primary`}></i>
                <span>{permission.label}</span>
              </div>
            </div>
          </Col>
        ))}
      </Row>

      <style jsx>{`
        .permission-section {
          transition: all 0.3s ease;
        }
        .permission-section:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        .permission-card {
          background-color: white;
          transition: all 0.2s ease;
        }
        .permission-card:hover {
          background-color: #f8f9fa;
          transform: translateY(-2px);
        }
      `}</style>
      <hr className="mt-4" />
    </div>
  );
};

export const FamiliesUserPermission = ({
  userPermission,
  setUserPermission,
}) => {
  // Define permission groups
  const familyPermissions = [
    { id: "READ_FAMILY", label: "Read Family", icon: "bi bi-eye" },
    { id: "WRITE_FAMILY", label: "Write Family", icon: "bi bi-pencil-square" },
    {
      id: "UPDATE_FAMILY",
      label: "Update Family",
      icon: "bi bi-arrow-clockwise",
    },
    {
      id: "UPDATE_FAMILY_HEAD",
      label: "Update Family Head",
      icon: "bi bi-person-gear",
    },
    { id: "DELETE_FAMILY", label: "Delete Family", icon: "bi bi-trash" },
  ];

  const familiesListPermissions = [
    {
      id: "READ_FAMILY_HEADS",
      label: "Read Families List",
      icon: "bi bi-list-ul",
    },
    {
      id: "READ_MEMBER_SEARCH",
      label: "Global Member Search List",
      icon: "bi bi-search",
    },
    {
      id: "READ_FAMILY_MEMBERS",
      label: "Family Member List",
      icon: "bi bi-people",
    },
  ];

  const memberPermissions = [
    {
      id: "READ_MEMBER",
      label: "Read Member Details",
      icon: "bi bi-person-badge",
    },
    {
      id: "WRITE_MEMBER",
      label: "Write Member Details",
      icon: "bi bi-person-plus",
    },
    {
      id: "UPDATE_MEMBER",
      label: "Update Member Details",
      icon: "bi bi-person-gear",
    },
    {
      id: "UPDATE_MEMBER_FAMILY",
      label: "Import Member",
      icon: "bi bi-file-earmark-arrow-up",
    },
    { id: "DELETE_MEMBER", label: "Delete Member", icon: "bi bi-person-x" },
  ];

  // Check permission states
  const checkPermissionGroup = (permissions) => {
    const allSelected = permissions.every((perm) =>
      userPermission?.includes(perm.id)
    );

    const someSelected =
      permissions.some((perm) => userPermission?.includes(perm.id)) &&
      !allSelected;

    return { allSelected, someSelected };
  };

  // Toggle all permissions in a group
  const handleToggleAllInGroup = (permissions, checked) => {
    if (checked) {
      // Add all permissions that aren't already included
      const newPermissions = [...userPermission];
      permissions.forEach((permission) => {
        if (!newPermissions.includes(permission.id)) {
          newPermissions.push(permission.id);
        }
      });
      setUserPermission(newPermissions);
    } else {
      // Remove all permissions in the group
      setUserPermission(
        userPermission?.filter(
          (permission) => !permissions.map((p) => p.id).includes(permission)
        )
      );
    }
  };

  // Toggle individual permission
  const handleTogglePermission = (permission, checked) => {
    if (checked) {
      setUserPermission((prev) => [...prev, permission]);
    } else {
      setUserPermission((prev) => prev.filter((p) => p !== permission));
    }
  };

  // Permission section component
  const PermissionSection = ({ title, permissions, colSize = 3 }) => {
    const { allSelected, someSelected } = checkPermissionGroup(permissions);
    const enabledCount = permissions.filter((p) =>
      userPermission?.includes(p.id)
    ).length;

    return (
      <div className="permission-section p-3 mb-3 bg-light rounded border">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <Form.Check
              id={`toggle-all-${title.toLowerCase().replace(" ", "-")}`}
              type="checkbox"
              checked={allSelected}
              indeterminate={someSelected}
              onChange={(e) =>
                handleToggleAllInGroup(permissions, e.target.checked)
              }
              className="me-3"
            />
            <p className="fw-bold text-primary mb-0 fs-5">{title}</p>
          </div>
          <div className="badge bg-primary text-white">
            {enabledCount} of {permissions.length} enabled
          </div>
        </div>

        <Row className="g-3">
          {permissions.map((permission) => (
            <Col sm={colSize} key={permission.id}>
              <div className="permission-card p-2 rounded border d-flex align-items-center">
                <Form.Check
                  id={`perm-${permission.id}`}
                  onChange={(e) =>
                    handleTogglePermission(permission.id, e.target.checked)
                  }
                  checked={userPermission?.includes(permission.id)}
                  type="switch"
                  className="me-2"
                />
                <div className="d-flex align-items-center">
                  <i className={`${permission.icon} me-2 text-primary`}></i>
                  <span>{permission.label}</span>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  return (
    <div>
      <PermissionSection
        title="Family Permission"
        permissions={familyPermissions}
      />

      <PermissionSection
        title="Families Permission"
        permissions={familiesListPermissions}
        colSize={4}
      />

      <PermissionSection
        title="Member Permission"
        permissions={memberPermissions}
      />

      <style jsx>{`
        .permission-section {
          transition: all 0.3s ease;
        }
        .permission-section:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        .permission-card {
          background-color: white;
          transition: all 0.2s ease;
        }
        .permission-card:hover {
          background-color: #f8f9fa;
          transform: translateY(-2px);
        }
      `}</style>
    </div>
  );
};

export const ZoneUserPermission = ({ userPermission, setUserPermission }) => {
  // Define permission groups
  const zonePermissions = [
    { id: "READ_ZONE", label: "Read Zones", icon: "bi bi-geo-alt" },
    { id: "WRITE_ZONE", label: "Write Zones", icon: "bi bi-geo-alt-fill" },
    { id: "UPDATE_ZONE", label: "Update Zones", icon: "bi bi-pin-map-fill" },
  ];

  const subZonePermissions = [
    { id: "READ_SUBZONE", label: "Read Sub Zones", icon: "bi bi-geo" },
    { id: "WRITE_SUBZONE", label: "Write Sub Zones", icon: "bi bi-geo-fill" },
    { id: "UPDATE_SUBZONE", label: "Update Sub Zones", icon: "bi bi-pin-map" },
  ];

  // Check permission states
  const checkPermissionGroup = (permissions) => {
    const allSelected = permissions.every((perm) =>
      userPermission?.includes(perm.id)
    );

    const someSelected =
      permissions.some((perm) => userPermission?.includes(perm.id)) &&
      !allSelected;

    return { allSelected, someSelected };
  };

  // Toggle all permissions in a group
  const handleToggleAllInGroup = (permissions, checked) => {
    if (checked) {
      // Add all permissions that aren't already included
      const newPermissions = [...userPermission];
      permissions.forEach((permission) => {
        if (!newPermissions.includes(permission.id)) {
          newPermissions.push(permission.id);
        }
      });
      setUserPermission(newPermissions);
    } else {
      // Remove all permissions in the group
      setUserPermission(
        userPermission?.filter(
          (permission) => !permissions.map((p) => p.id).includes(permission)
        )
      );
    }
  };

  // Toggle individual permission
  const handleTogglePermission = (permission, checked) => {
    if (checked) {
      setUserPermission((prev) => [...prev, permission]);
    } else {
      setUserPermission((prev) => prev.filter((p) => p !== permission));
    }
  };

  // Permission section component
  const PermissionSection = ({ title, permissions }) => {
    const { allSelected, someSelected } = checkPermissionGroup(permissions);
    const enabledCount = permissions.filter((p) =>
      userPermission?.includes(p.id)
    ).length;

    return (
      <div className="permission-section p-3 mb-3 bg-light rounded border">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <Form.Check
              id={`toggle-all-${title.toLowerCase().replace(" ", "-")}`}
              type="checkbox"
              checked={allSelected}
              indeterminate={someSelected}
              onChange={(e) =>
                handleToggleAllInGroup(permissions, e.target.checked)
              }
              className="me-3"
            />
            <p className="fw-bold text-primary mb-0 fs-5">{title}</p>
          </div>
          <div className="badge bg-primary text-white">
            {enabledCount} of {permissions.length} enabled
          </div>
        </div>

        <Row className="g-3">
          {permissions.map((permission) => (
            <Col sm={4} key={permission.id}>
              <div className="permission-card p-2 rounded border d-flex align-items-center">
                <Form.Check
                  id={`perm-${permission.id}`}
                  onChange={(e) =>
                    handleTogglePermission(permission.id, e.target.checked)
                  }
                  checked={userPermission?.includes(permission.id)}
                  type="switch"
                  className="me-2"
                />
                <div className="d-flex align-items-center">
                  <i className={`${permission.icon} me-2 text-primary`}></i>
                  <span>{permission.label}</span>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  return (
    <div>
      <PermissionSection
        title="Zones Permission"
        permissions={zonePermissions}
      />

      <PermissionSection
        title="Sub Zones Permission"
        permissions={subZonePermissions}
      />

      <style jsx>{`
        .permission-section {
          transition: all 0.3s ease;
        }
        .permission-section:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        .permission-card {
          background-color: white;
          transition: all 0.2s ease;
        }
        .permission-card:hover {
          background-color: #f8f9fa;
          transform: translateY(-2px);
        }
      `}</style>
    </div>
  );
};

export const FinancialUserPermission = ({
  userPermission,
  setUserPermission,
}) => {
  // Define permission groups
  const contributionPermissions = [
    {
      id: "READ_DONATION",
      label: "Read Contribution",
      icon: "bi bi-currency-dollar",
    },
    {
      id: "WRITE_DONATION",
      label: "Write Contribution",
      icon: "bi bi-cash-coin",
    },
    {
      id: "DELETE_DONATION",
      label: "Delete Contribution",
      icon: "bi bi-trash",
    },
  ];

  const expensePermissions = [
    { id: "READ_EXPENSE", label: "Read Expenses", icon: "bi bi-journal-text" },
    {
      id: "WRITE_EXPENSE",
      label: "Write Expenses",
      icon: "bi bi-pencil-square",
    },
    { id: "DELETE_EXPENSE", label: "Delete Expenses", icon: "bi bi-trash" },
  ];

  const dioceseDonationPermissions = [
    {
      id: "READ_COLLECTION",
      label: "Read Diocese Donation",
      icon: "bi bi-book",
    },
    {
      id: "WRITE_COLLECTION",
      label: "Write Diocese Donation",
      icon: "bi bi-pen",
    },
    {
      id: "DELETE_COLLECTION",
      label: "Delete Diocese Donation",
      icon: "bi bi-trash",
    },
  ];

  const csfPermissions = [
    { id: "READ_CSF", label: "Read CSF", icon: "bi bi-file-earmark-text" },
    { id: "WRITE_CSF", label: "Write CSF", icon: "bi bi-file-earmark-plus" },
    {
      id: "UPDATE_CSF",
      label: "Update CSF",
      icon: "bi bi-file-earmark-arrow-up",
    },
    { id: "DELETE_CSF", label: "Delete CSF", icon: "bi bi-file-earmark-x" },
    { id: "READ_FAMILY_CSF", label: "Read Family CSF", icon: "bi bi-people" },
  ];

  // Check permission states
  const checkPermissionGroup = (permissions) => {
    const allSelected = permissions.every((perm) =>
      userPermission?.includes(perm.id)
    );

    const someSelected =
      permissions.some((perm) => userPermission?.includes(perm.id)) &&
      !allSelected;

    return { allSelected, someSelected };
  };

  // Toggle all permissions in a group
  const handleToggleAllInGroup = (permissions, checked) => {
    if (checked) {
      // Add all permissions that aren't already included
      const newPermissions = [...userPermission];
      permissions.forEach((permission) => {
        if (!newPermissions.includes(permission.id)) {
          newPermissions.push(permission.id);
        }
      });
      setUserPermission(newPermissions);
    } else {
      // Remove all permissions in the group
      setUserPermission(
        userPermission?.filter(
          (permission) => !permissions.map((p) => p.id).includes(permission)
        )
      );
    }
  };

  // Toggle individual permission
  const handleTogglePermission = (permission, checked) => {
    if (checked) {
      setUserPermission((prev) => [...prev, permission]);
    } else {
      setUserPermission((prev) => prev.filter((p) => p !== permission));
    }
  };

  // Permission section component
  const PermissionSection = ({ title, permissions, colSize = 3 }) => {
    const { allSelected, someSelected } = checkPermissionGroup(permissions);
    const enabledCount = permissions.filter((p) =>
      userPermission?.includes(p.id)
    ).length;

    return (
      <div className="permission-section p-3 mb-3 bg-light rounded border">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <Form.Check
              id={`toggle-all-${title.toLowerCase().replace(/\s+/g, "-")}`}
              type="checkbox"
              checked={allSelected}
              indeterminate={someSelected}
              onChange={(e) =>
                handleToggleAllInGroup(permissions, e.target.checked)
              }
              className="me-3"
            />
            <p className="fw-bold text-primary mb-0 fs-5">{title}</p>
          </div>
          <div className="badge bg-primary text-white">
            {enabledCount} of {permissions.length} enabled
          </div>
        </div>

        <Row className="g-3">
          {permissions.map((permission) => (
            <Col sm={colSize} key={permission.id}>
              <div className="permission-card p-2 rounded border d-flex align-items-center">
                <Form.Check
                  id={`perm-${permission.id}`}
                  onChange={(e) =>
                    handleTogglePermission(permission.id, e.target.checked)
                  }
                  checked={userPermission?.includes(permission.id)}
                  type="switch"
                  className="me-2"
                />
                <div className="d-flex align-items-center">
                  <i className={`${permission.icon} me-2 text-primary`}></i>
                  <span>{permission.label}</span>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  return (
    <div>
      <PermissionSection
        title="Contribution Permission"
        permissions={contributionPermissions}
      />

      <PermissionSection
        title="Expenses Permission"
        permissions={expensePermissions}
      />

      <PermissionSection
        title="Diocese Donation Permission"
        permissions={dioceseDonationPermissions}
      />

      <PermissionSection
        title="Church Support Fund Permission"
        permissions={csfPermissions}
      />

      <style jsx>{`
        .permission-section {
          transition: all 0.3s ease;
        }
        .permission-section:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        .permission-card {
          background-color: white;
          transition: all 0.2s ease;
        }
        .permission-card:hover {
          background-color: #f8f9fa;
          transform: translateY(-2px);
        }
      `}</style>
    </div>
  );
};

export const SacramentUserPermission = ({
  userPermission,
  setUserPermission,
}) => {
  // Define permission groups
  const baptismPermissions = [
    { id: "READ_BAPTISM", label: "Read Baptism", icon: "bi bi-journal-text" },
    { id: "WRITE_BAPTISM", label: "Write Baptism", icon: "bi bi-journal-plus" },
    {
      id: "UPDATE_BAPTISM",
      label: "Update Baptism",
      icon: "bi bi-journal-check",
    },
    { id: "DELETE_BAPTISM", label: "Delete Baptism", icon: "bi bi-journal-x" },
  ];

  const communionPermissions = [
    { id: "READ_COMMUNION", label: "Read Communion", icon: "bi bi-cup-hot" },
    {
      id: "WRITE_COMMUNION",
      label: "Write Communion",
      icon: "bi bi-pencil-square",
    },
    {
      id: "UPDATE_COMMUNION",
      label: "Update Communion",
      icon: "bi bi-arrow-clockwise",
    },
    { id: "DELETE_COMMUNION", label: "Delete Communion", icon: "bi bi-trash" },
  ];

  const confirmationPermissions = [
    {
      id: "READ_CONFIRMATION",
      label: "Read Confirmation",
      icon: "bi bi-file-earmark-text",
    },
    {
      id: "WRITE_CONFIRMATION",
      label: "Write Confirmation",
      icon: "bi bi-file-earmark-plus",
    },
    {
      id: "UPDATE_CONFIRMATION",
      label: "Update Confirmation",
      icon: "bi bi-file-earmark-check",
    },
    {
      id: "DELETE_CONFIRMATION",
      label: "Delete Confirmation",
      icon: "bi bi-file-earmark-x",
    },
  ];

  const marriagePermissions = [
    { id: "READ_MARRIAGE", label: "Read Marriage", icon: "bi bi-people" },
    {
      id: "WRITE_MARRIAGE",
      label: "Write Marriage",
      icon: "bi bi-pencil-square",
    },
    {
      id: "UPDATE_MARRIAGE",
      label: "Update Marriage",
      icon: "bi bi-arrow-clockwise",
    },
    { id: "DELETE_MARRIAGE", label: "Delete Marriage", icon: "bi bi-trash" },
  ];

  // Check permission states
  const checkPermissionGroup = (permissions) => {
    const allSelected = permissions.every((perm) =>
      userPermission?.includes(perm.id)
    );

    const someSelected =
      permissions.some((perm) => userPermission?.includes(perm.id)) &&
      !allSelected;

    return { allSelected, someSelected };
  };

  // Toggle all permissions in a group
  const handleToggleAllInGroup = (permissions, checked) => {
    if (checked) {
      // Add all permissions that aren't already included
      const newPermissions = [...userPermission];
      permissions.forEach((permission) => {
        if (!newPermissions.includes(permission.id)) {
          newPermissions.push(permission.id);
        }
      });
      setUserPermission(newPermissions);
    } else {
      // Remove all permissions in the group
      setUserPermission(
        userPermission?.filter(
          (permission) => !permissions.map((p) => p.id).includes(permission)
        )
      );
    }
  };

  // Toggle individual permission
  const handleTogglePermission = (permission, checked) => {
    if (checked) {
      setUserPermission((prev) => [...prev, permission]);
    } else {
      setUserPermission((prev) => prev.filter((p) => p !== permission));
    }
  };

  // Permission section component
  const PermissionSection = ({ title, permissions }) => {
    const { allSelected, someSelected } = checkPermissionGroup(permissions);
    const enabledCount = permissions.filter((p) =>
      userPermission?.includes(p.id)
    ).length;

    return (
      <div className="permission-section p-3 mb-3 bg-light rounded border">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <Form.Check
              id={`toggle-all-${title.toLowerCase().replace(/\s+/g, "-")}`}
              type="checkbox"
              checked={allSelected}
              indeterminate={someSelected}
              onChange={(e) =>
                handleToggleAllInGroup(permissions, e.target.checked)
              }
              className="me-3"
            />
            <p className="fw-bold text-primary mb-0 fs-5">{title}</p>
          </div>
          <div className="badge bg-primary text-white">
            {enabledCount} of {permissions.length} enabled
          </div>
        </div>

        <Row className="g-3">
          {permissions.map((permission) => (
            <Col sm={3} key={permission.id}>
              <div className="permission-card p-2 rounded border d-flex align-items-center">
                <Form.Check
                  id={`perm-${permission.id}`}
                  onChange={(e) =>
                    handleTogglePermission(permission.id, e.target.checked)
                  }
                  checked={userPermission?.includes(permission.id)}
                  type="switch"
                  className="me-2"
                />
                <div className="d-flex align-items-center">
                  <i className={`${permission.icon} me-2 text-primary`}></i>
                  <span>{permission.label}</span>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  return (
    <div>
      <PermissionSection
        title="Baptism Permission"
        permissions={baptismPermissions}
      />

      <PermissionSection
        title="Holy Communion Permission"
        permissions={communionPermissions}
      />

      <PermissionSection
        title="Confirmation Permission"
        permissions={confirmationPermissions}
      />

      <PermissionSection
        title="Marriage Permission"
        permissions={marriagePermissions}
      />

      <style jsx>{`
        .permission-section {
          transition: all 0.3s ease;
        }
        .permission-section:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        .permission-card {
          background-color: white;
          transition: all 0.2s ease;
        }
        .permission-card:hover {
          background-color: #f8f9fa;
          transform: translateY(-2px);
        }
      `}</style>
    </div>
  );
};
