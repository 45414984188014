import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { HolyCommunionValidationSchema } from "../../validations/holy-communion";
import Swal from "sweetalert2";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import TextInput from "../../components/form/TextInput";
import DateInput from "../../components/form/DateInput";
import SelectInput from "../../components/form/SelectInput";
import TextArea from "../../components/form/TextArea";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { EditHolyConfirmationValidationSchema } from "../../validations/holy-confirmation";

function capitalizeText(text) {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const MakeExtToIntHolyConfirmationModal = ({
  show,
  setShow,
  basicDetails,
  memberId,
  getFamilyMemberDetails,
  commonAPIRequest,
  closeOffset,
  baptismDetails,
  contactDetails,
  familyDetails,
  currentHolyConfirmation,
  holyCommunionDetails,
  callGetHolyConfirmationDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [baptismName, setBaptismName] = useState(true);
  const [showAlternateRef, setShowAlternateRef] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      fatherName: "",
      motherName: "",
      dob: "",
      gender: "",
      dateOfBaptism: "",
      holyCommunionDate: "",
      holyCommunionForValidationDate: "",
      address: "",
      mobileNum: "",
      extConfirmation: false,
      // baptismChurchName: "",
      // baptismChurchAddress: "",
      // holyCommunionChurchName: "",
      confirmationDate: "",
      registerRefNumber: null,
      alternateRegRefNumber: null,
      holyConfirmationDateNotSure: false,
      bookNumber: "",
      ministerName: "",
      baptismRegister: "",
    },
    validationSchema: EditHolyConfirmationValidationSchema,
    onSubmit: () => {
      Swal.fire({
        title: "<strong>Are you sure?</strong>",
        icon: "warning",
        html: `
        <dl>
          <dt>Date Of Holy Communion</dt>
          <dd>${new Date(formik.values?.holyCommunionDate).toLocaleDateString(
            "en-GB",
            {
              month: "long",
              day: "numeric",
              year: "numeric",
            }
          )}</dd>
          <dt>Date Of Holy Confirmation </dt>
          <dd>${new Date(formik.values?.confirmationDate).toLocaleDateString(
            "en-GB",
            {
              month: "long",
              day: "numeric",
              year: "numeric",
            }
          )}</dd>
        </dl>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: `Yes, Sure!`,
        confirmButtonAriaLabel: "Yes, Sure!",
        cancelButtonText: `No`,
        cancelButtonAriaLabel: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          callUpdateHolyConfirmationDetailsApi();
        }
      });
    },
  });

  // function to call API for Update New Holy Confirmation start

  const callUpdateHolyConfirmationDetailsApi = () => {
    let serviceParams = {
      api: API.EXTERNAL_HOLY_CONFIRMATION_API,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        confirmationDetail: {
          confirmationId: currentHolyConfirmation?.confirmationId,
          firstName: formik.values.firstName.trim(),
          middleName: formik.values.middleName.trim(),
          lastName: formik.values.lastName.trim(),
          fatherName: formik.values.fatherName.trim(),
          motherName: formik.values.motherName.trim(),
          communicantAddress: formik.values.address.trim(),
          mobileNum: formik.values.mobileNum.trim(),
          dob: new Date(formik.values.dob),
          baptismDate: new Date(formik.values.dateOfBaptism),
          // baptismChurchName: formik.values.baptismChurchName.trim(),
          // baptismChurchAddress: formik.values.baptismChurchAddress.trim(),
          godFatherName: capitalizeText(
            formik.values?.godFatherFirstName.trim()
          ),
          godFatherSurName: capitalizeText(
            formik.values?.godFatherLastName.trim()
          ),
          godMotherName: capitalizeText(
            formik.values?.godMotherFirstName.trim()
          ),
          godMotherSurName: capitalizeText(
            formik.values?.godMotherLastName.trim()
          ),
          gender: formik.values?.gender,
          holyCommunionDate: new Date(formik.values.holyCommunionDate),
          // holyCommunionChurchName: formik.values.holyCommunionChurchName.trim(),
          ministerName: capitalizeText(formik.values?.ministerName.trim()),
          baptismRegister: formik.values?.baptismRegister,
          regParishoner: true,
          confirmationDate: new Date(formik.values.confirmationDate),
          extConfirmation: false,
          extConfirmationChurch: null,
          extConfirmationCity: null,
          regReferenceNum: formik.values?.registerRefNumber
            ? +formik.values?.registerRefNumber
            : null,
          altRegReferenceNum: formik.values?.alternateRegRefNumber
            ? +formik.values?.alternateRegRefNumber
            : null,
          confirmationDateAccurate: !formik.values.holyConfirmationDateNotSure,
          bookNumber: formik.values?.bookNumber
            ? formik.values?.bookNumber
            : null,
        },
        memberId: memberId,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          getFamilyMemberDetails();
          closeOffset();
          Swal.fire(
            "Success",
            `Holy Confirmation Updated Successfully.`,
            "success"
          );
          setShow(false);
          formik.resetForm();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Update New Holy Communion end

  const handleCheckboxChange = (e) => {
    setShowAlternateRef(e.target.checked);
    if (!e.target.checked) {
      // Clear the alternate reference number when unchecking
      formik.setFieldValue("alternateRegRefNumber", null);
    }
  };

  //  function to call API for Add New Holy Communion end

  useEffect(() => {
    if (show) {
      if (basicDetails?.baptismName) {
        formik.setFieldValue("firstName", basicDetails?.baptismName);
        formik.setFieldValue("middleName", "");
        formik.setFieldValue("lastName", "");
      } else {
        formik.setFieldValue("firstName", basicDetails?.firstName);
        formik.setFieldValue("middleName", basicDetails?.middleName);
        formik.setFieldValue("lastName", basicDetails?.lastName);
      }
      formik.setFieldValue("fatherName", basicDetails?.fatherName);
      formik.setFieldValue("motherName", basicDetails?.motherName);
      formik.setFieldValue("dob", new Date(basicDetails?.dateOfBirth));
      formik.setFieldValue("gender", basicDetails?.gender);
      formik.setFieldValue(
        "dateOfBaptism",
        new Date(baptismDetails?.baptismDate)
      );

      formik.setFieldValue(
        "holyCommunionDate",
        new Date(holyCommunionDetails?.holyCommunionDate)
      );
      formik.setFieldValue(
        "holyCommunionForValidationDate",
        new Date(
          new Date(holyCommunionDetails?.holyCommunionDate).setDate(
            new Date(holyCommunionDetails?.holyCommunionDate).getDate() - 1
          )
        )
      );

      formik.setFieldValue("mobileNum", contactDetails?.phNumber);
      formik.setFieldValue("address", familyDetails?.address?.addressLine1);
    }
  }, [show]);

  console.log("contactDetails", contactDetails);

  useEffect(() => {
    if (baptismName) {
      if (basicDetails?.baptismName) {
        formik.setFieldValue("firstName", basicDetails?.baptismName);
        formik.setFieldValue("middleName", "");
        formik.setFieldValue("lastName", "");
      }
    } else {
      formik.setFieldValue("firstName", basicDetails?.firstName);
      formik.setFieldValue(
        "middleName",
        basicDetails?.middleName ? basicDetails?.middleName : ""
      );
      formik.setFieldValue(
        "lastName",
        basicDetails?.lastName ? basicDetails?.lastName : ""
      );
    }
  }, [baptismName]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="xl"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Make Holy Confirmation Certificate
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
            <span
              style={{ marginLeft: -54 }}
              className="fs-18 text-primary fw-bold"
            >
              Updating...
            </span>
          </div>
        ) : (
          <Row className="g-3">
            <Col sm={!baptismName ? 4 : 12}>
              <TextInput
                label={baptismName ? "Baptism Name" : "First Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"firstName"}
                value={formik.values.firstName}
                error={formik.errors.firstName}
                touched={formik.touched.firstName}
                disabled={basicDetails?.firstName ? true : false}
              />
              {basicDetails?.baptismName && (
                <Form.Check
                  type="switch"
                  className="mt-1"
                  checked={baptismName}
                  label="Use Baptism Name"
                  onChange={(e) => {
                    setBaptismName(e.target.checked);
                  }}
                />
              )}
            </Col>
            {!baptismName && (
              <Col sm={4}>
                <TextInput
                  label={"Middle Name"}
                  setFieldValue={formik.setFieldValue}
                  keyword={"middleName"}
                  value={formik.values.middleName}
                  error={formik.errors.middleName}
                  touched={formik.touched.middleName}
                  disabled={true}
                />
              </Col>
            )}
            {!baptismName && (
              <Col sm={4}>
                <TextInput
                  label={"Last Name"}
                  setFieldValue={formik.setFieldValue}
                  keyword={"lastName"}
                  value={formik.values.lastName}
                  error={formik.errors.lastName}
                  touched={formik.touched.lastName}
                  disabled={true}
                />
              </Col>
            )}
            <Col sm={6}>
              <TextInput
                label={"Father's Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"fatherName"}
                value={formik.values.fatherName}
                error={formik.errors.fatherName}
                touched={formik.touched.fatherName}
                // disabled={basicDetails?.fatherName ? true : false}
                disabled
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"Mother's Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"motherName"}
                value={formik.values.motherName}
                error={formik.errors.motherName}
                touched={formik.touched.motherName}
                // disabled={basicDetails?.motherName ? true : false}
                disabled
              />
            </Col>
            <Col sm={4}>
              <DateInput
                label={"Date Of Birth"}
                setFieldValue={formik.setFieldValue}
                keyword={"dob"}
                value={formik.values.dob}
                error={formik.errors.dob}
                touched={formik.touched.dob}
                disabled={basicDetails?.dateOfBirth ? true : false}
              />
            </Col>
            <Col sm={4}>
              <DateInput
                label={"Date Of Baptism"}
                setFieldValue={formik.setFieldValue}
                keyword={"dateOfBaptism"}
                value={formik.values.dateOfBaptism}
                error={formik.errors.dateOfBaptism}
                touched={formik.touched.dateOfBaptism}
                disabled={currentHolyConfirmation?.baptismDate ? true : false}
              />
            </Col>
            <Col sm={4}>
              <DateInput
                label={"Date Of Holy Communion"}
                setFieldValue={formik.setFieldValue}
                keyword={"holyCommunionDate"}
                value={formik.values.holyCommunionDate}
                error={formik.errors.holyCommunionDate}
                touched={formik.touched.holyCommunionDate}
                disabled={
                  currentHolyConfirmation?.holyCommunionDate ? true : false
                }
              />
            </Col>
            <Col sm={6}>
              <SelectInput
                label={"Gender"}
                selectOptions={[
                  { label: "Male", value: "Male" },
                  { label: "Female", value: "Female" },
                ]}
                setFieldValue={formik.setFieldValue}
                keyword={"gender"}
                value={formik.values.gender}
                error={formik.errors.gender}
                touched={formik.touched.gender}
                disabled={basicDetails?.gender ? true : false}
              />
            </Col>

            <Col sm={6}>
              <TextInput
                label={"Mobile Number"}
                setFieldValue={formik.setFieldValue}
                keyword={"mobileNum"}
                value={formik.values.mobileNum}
                error={formik.errors.mobileNum}
                touched={formik.touched.mobileNum}
              />
            </Col>

            <Col sm={6}>
              <TextInput
                label={"God Father's First Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"godFatherFirstName"}
                value={formik.values.godFatherFirstName}
                error={formik.errors.godFatherFirstName}
                touched={formik.touched.godFatherFirstName}
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"God Father's Last Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"godFatherLastName"}
                value={formik.values.godFatherLastName}
                error={formik.errors.godFatherLastName}
                touched={formik.touched.godFatherLastName}
              />
            </Col>

            <Col sm={6}>
              <TextInput
                label={"God Mother's First Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"godMotherFirstName"}
                value={formik.values.godMotherFirstName}
                error={formik.errors.godMotherFirstName}
                touched={formik.touched.godMotherFirstName}
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"God Mother's Last Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"godMotherLastName"}
                value={formik.values.godMotherLastName}
                error={formik.errors.godMotherLastName}
                touched={formik.touched.godMotherLastName}
              />
            </Col>

            <Col sm={12}>
              <DateInput
                uncertain={formik.values.holyConfirmationDateNotSure}
                label={"Date Of Holy Confirmation"}
                setFieldValue={formik.setFieldValue}
                keyword={"confirmationDate"}
                value={formik.values.confirmationDate}
                error={formik.errors.confirmationDate}
                touched={formik.touched.confirmationDate}
              />
            </Col>
            <Col className="mt-2" sm={12}>
              <Form.Check
                type="checkbox"
                label="Not sure if holy confirmation date is correct"
                onChange={(e) => {
                  formik.setFieldValue(
                    "holyConfirmationDateNotSure",
                    e.target.checked
                  );
                }}
                checked={formik.values.holyConfirmationDateNotSure}
              />
            </Col>
            {/* <Col sm={6}>
              <TextInput
                label={"Baptism Church Name"}
                placeholder={"e.g. St. Mary's Church"}
                setFieldValue={formik.setFieldValue}
                keyword={"baptismChurchName"}
                value={formik.values.baptismChurchName}
                error={formik.errors.baptismChurchName}
                touched={formik.touched.baptismChurchName}
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"Baptism Church Address"}
                setFieldValue={formik.setFieldValue}
                keyword={"baptismChurchAddress"}
                value={formik.values.baptismChurchAddress}
                error={formik.errors.baptismChurchAddress}
                touched={formik.touched.baptismChurchAddress}
              />
            </Col>
            <Col sm={12}>
              <TextInput
                label={"Holy Communion Church Name"}
                placeholder={"e.g. St. Mary's Church"}
                setFieldValue={formik.setFieldValue}
                keyword={"holyCommunionChurchName"}
                value={formik.values.holyCommunionChurchName}
                error={formik.errors.holyCommunionChurchName}
                touched={formik.touched.holyCommunionChurchName}
              />
            </Col> */}

            <Col sm={6}>
              <TextInput
                label={"Minister's Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"ministerName"}
                value={formik.values.ministerName}
                error={formik.errors.ministerName}
                touched={formik.touched.ministerName}
              />
            </Col>

            <Col sm={6}>
              <TextInput
                label={"Entry In Baptiam Register"}
                placeholder={"e.g. Baptism Ref Num"}
                setFieldValue={formik.setFieldValue}
                keyword={"baptismRegister"}
                value={formik.values.baptismRegister}
                error={formik.errors.baptismRegister}
                touched={formik.touched.baptismRegister}
              />
            </Col>

            <Col sm={showAlternateRef ? 4 : 6}>
              <TextInput
                label={"Register Reference Number"}
                setFieldValue={formik.setFieldValue}
                keyword={"registerRefNumber"}
                value={formik.values.registerRefNumber}
                error={formik.errors.registerRefNumber}
                touched={formik.touched.registerRefNumber}
                type={"number"}
              />
            </Col>

            {showAlternateRef && (
              <Col sm={4}>
                <TextInput
                  label={"Alternate Reg. Ref. No."}
                  setFieldValue={formik.setFieldValue}
                  keyword={"alternateRegRefNumber"}
                  value={formik.values.alternateRegRefNumber}
                  error={formik.errors.alternateRegRefNumber}
                  touched={formik.touched.alternateRegRefNumber}
                  type="number"
                />
              </Col>
            )}

            <Col sm={showAlternateRef ? 4 : 6}>
              <TextInput
                label={"Book Reference:"}
                setFieldValue={formik.setFieldValue}
                keyword={"bookNumber"}
                value={formik.values.bookNumber}
                error={formik.errors.bookNumber}
                touched={formik.touched.bookNumber}
                type="text"
              />
            </Col>

            <Col sm={12}>
              <div className="mb-3">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="showAlternateRef"
                    checked={showAlternateRef}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="showAlternateRef"
                  >
                    I have an alternate reference number which will be shown on
                    the certificate
                  </label>
                </div>
              </div>
            </Col>

            <Col sm={12}>
              <TextArea
                label={"Residential Address"}
                setFieldValue={formik.setFieldValue}
                keyword={"address"}
                value={formik.values.address}
                error={formik.errors.address}
                touched={formik.touched.address}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={formik.handleSubmit}>
            Update
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(MakeExtToIntHolyConfirmationModal);
