import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { useDispatch } from "react-redux";
import { Badge, Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import ZonePlaceholder from "../../components/placeholders/ZonePlaceholder";
import Avatar from "../../components/Avatar";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import noSearchAnimation from "../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import maleImg from "../../assets/img/man_delhi_diocese_theme.png";
import femaleImg from "../../assets/img/women_delhi_diocese_theme.png";
import { withAPIRequest } from "../../HOC/withAPIRequest";

const alphabetsOnly = /^[a-zA-Z. ,]*$/;

const MemberValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed.")
    .min(3),
});

const LinkBaptismToMemberModal = ({ show, setShow, commonAPIRequest }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: MemberValidationSchema,
    onSubmit: (values) => {
      const fullNameArray = values.name.split(/(\s+)/).filter(function (e) {
        return e.trim().length > 0;
      });
      fullNameArray.length === 1
        ? callSearchUsersApi(fullNameArray[0], "")
        : callSearchUsersApi(
            fullNameArray[0],
            fullNameArray[fullNameArray.length - 1]
          );
    },
  });

  const callSearchUsersApi = (firstName, lastName) => {
    let serviceParams = {
      api: `${API.SEARCH_MEMBER}?firstName=${firstName.trim()}${
        lastName && `&lastName=${lastName.trim()}`
      }&filterBy=All`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            setMembers(
              result.payload.searchSuggestions.length !== 0
                ? result.payload.searchSuggestions
                : []
            )
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        formik.handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-primary fw-bold">
          Link Baptism Certificate
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="g-5">
          <Col xl>
            <div className="form-search py-2  ">
              <i className="ri-search-line"></i>
              <Form.Control
                onChange={(e) => formik.setFieldValue("name", e.target.value)}
                type="text"
                value={formik.values.name}
                placeholder="e.g. Joseph George"
              />
              <Button onClick={formik.handleSubmit} variant="primary">
                Search
              </Button>
            </div>
            <div className="mb-0">
              {formik.errors.name && formik.touched.name && (
                <p className="text-danger">*{formik.errors.name}</p>
              )}
            </div>
          </Col>
        </Row>

        <hr />

        <Row className="g-5">
          <Col xl>
            {/* <h2 className="main-title">Search All Member's</h2> */}
            <div className="main-label-group mb-3">
              <label>Results</label>
              <Button
                onClick={() => {
                  setMembers([]);
                  formik.setFieldValue("name", "");
                }}
                variant="outline-primary"
              >
                Reset
              </Button>
            </div>
            <Row className="g-2 g-xxl-3 mb-5">
              {loading && <ZonePlaceholder />}
              {members.map((member, index) => (
                <Col lg={12} key={index}>
                  {
                    <Card className="card-people shadow-sm mb-3">
                      <Card.Body>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              img={
                                member.gender === "Female" ? femaleImg : maleImg
                              }
                              size="xl"
                            />
                            <div className="ms-3">
                              <h6 className="mt-3">
                                <Link
                                  to={"/admin/member-details"}
                                  state={{
                                    memberId: member.memberId,
                                    back: true,
                                  }}
                                >
                                  {member.middleName
                                    ? `${member.firstName} ${member.middleName} ${member.lastName}`
                                    : `${member.firstName} ${member.lastName}`}
                                </Link>
                                {member?.active ? (
                                  <Badge
                                    className="ms-1 fw-bold"
                                    bg="success"
                                    pill
                                  >
                                    Active
                                  </Badge>
                                ) : (
                                  <Badge
                                    className="ms-1 fw-bold"
                                    bg="danger"
                                    pill
                                  >
                                    Inactive
                                  </Badge>
                                )}
                              </h6>
                              <p className="m-0 fw-bold text-primary">
                                {member.churchFamilyId}
                              </p>
                              <p className="m-0">
                                {member?.emailAddress
                                  ? member?.emailAddress
                                  : "-"}
                              </p>
                              {member?.familyHead ? (
                                <Badge pill bg="primary">
                                  Head Of Family
                                </Badge>
                              ) : (
                                <Badge pill bg="warning">
                                  Member
                                </Badge>
                              )}
                            </div>
                          </div>

                          <Link
                            className="btn btn-primary"
                            onClick={() => {}}
                            state={{
                              familyId: member?.familyId,
                            }}
                            to={"/admin/family-details"}
                          >
                            <i
                              style={{ fontSize: 18 }}
                              className="ri-team-line me-2 align-self-center"
                            ></i>
                            Family Details
                          </Link>
                        </div>
                      </Card.Body>
                    </Card>
                  }
                </Col>
              ))}
              {!loading && members.length === 0 && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className="text-center"
                  >
                    <Lottie
                      style={{ width: 180, height: 180 }}
                      animationData={noSearchAnimation}
                      loop={true}
                    />
                  </div>
                  <h3 className="text-center pt-3 fw-bold">
                    No Parishioner Found
                  </h3>
                  <h5 className="text-center fw-400">
                    Please Try With Another Name.
                  </h5>
                </div>
              )}
            </Row>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default withAPIRequest(LinkBaptismToMemberModal);
